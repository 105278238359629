export const customTheme = {

  drawer: {
    defaultProps: {
      size: 300,
      overlay: true,
      placement: "left",
      overlayProps: undefined,
      className: "",
      dismiss: undefined,
      onClose: undefined,
      transition: {
        type: "tween",
        duration: 0.3,
      },
    },
    styles: {
      base: {
        drawer: {
          backgroundColor: "bg-gradient-to-r from-[#1F1C1C] to-[#413A39]",
          width: "w-full",
          boxShadow: "shadow-2xl shadow-gray-900/10",
        },
        overlay: {
          backgroundColor: "bg-black",
          backgroundOpacity: "bg-opacity-40",
          backdropBlur: "backdrop-blur-sm",
        },
      },
    },
  },

  list: {
    defaultProps: {
      ripple: false,
      className: "",
    },
    styles: {
      base: {
        list: {
          display: "flex",
          flexDirection: "flex-col",
          gap: "gap-0",
          minWidth: "min-w-[240px]",
          p: "p-0",
          color: "text-grayscale-bright",
        },
        item: {
          initial: {
            padding: "px-4 py-3.5",
            borderRadius: "rounded-none",
            bg: "hover:bg-white focus:bg-white active:bg-white",
            color: "hover:text-black focus:text-black active:text-black",
            outline: "outline-none",
            className:"border-b border-primary",
          },
          selected: {
            bg: "bg-white",
            color: "text-black",
          },
          disabled: {
            bg: "hover:bg-transparent focus:bg-transparent active:bg-transparent",
            color: "hover:text-white focus:text-white active:text-white",
          },
        },
        itemPrefix: {
          display: "grid",
          placeItems: "place-items-center",
          marginRight: "mr-4",
        },
        itemSuffix: {
          display: "grid",
          placeItems: "place-items-center",
          marginRight: "ml-auto justify-self-end",
        },
      },
    },
  },

  menu: { // Builds the top-right User Profile menu
    defaultProps: {
      placement: "bottom",
      offset: 5,
      dismiss: {
        itemPress: true
      },
      animate: {
        unmount: {},
        mount: {},
      },
      lockScroll: false,
    },
    styles: {
      base: {
        menu: {
          bg: "bg-gradient-to-r from-[#1F1C1C] to-[#413A39]",
          minWidth: "min-w-[200px]",
          p: "p-0",
          border: "border border-primary",
          borderRadius: "rounded-sm",
          boxShadow: "shadow-lg shadow-black/30",
          color: "text-grayscale-bright",
        },
        item: {
          initial: {
            pt: "pt-2.5",
            pb: "pb-2.5",
            px: "px-4",
            borderRadius: "rounded-none",
            bg: "hover:bg-white focus:bg-white",
            color: "hover:text-black focus:text-black active:text-black",
            className:"flex items-center gap-2 border-b border-primary",
          },
          disabled: {
            bg: "hover:bg-transparent focus:bg-transparent active:bg-transparent",
            color: "hover:text-white focus:text-white active:text-white",
          },
        },
      },
    },
  },  

  navbar: {
    defaultProps: {
      variant: "filled",
      shadow: false,
      blurred: false,
      fullWidth: true,
    },    
    styles: {
      base: {
        navbar: {
          initial: {
            borderRadius: "rounded-none",
            py: "py-4",          
          },
        },
        mobileNav: {
          display: "block",
          width: "w-full",
          basis: "basis-full",
          overflow: "overflow-hidden",
        },
      },
    },
  },

  button: {
    defaultProps: {
      variant: "filled",
      size: "md",
      ripple: false,
      className: "flex justify-center items-center",
    },
    styles: {
      base: {
        initial: {
          verticalAlign: "align-middle",
          userSelect: "select-none",
          fontFamily: "font-sans",
          fontWeight: "font-regular",
          textAlign: "text-center",
          textTransform: "uppercase",
        },
        fullWidth: {
          display: "block",
          width: "w-full",
        },
      },
      sizes: {
        sm: {
          fontSize: "text-sm",
          py: "py-2",
          px: "px-4",
          borderRadius: "rounded-full",
        },
        md: {
          fontSize: "text-md",
          py: "py-4",
          px: "px-6",
          borderRadius: "rounded-full",
        },
      },
    },
  },

  iconButton: {
    defaultProps: {
      variant: "filled",
      size: "md",
      fullWidth: false,
      ripple: false,
      className: "flex justify-center items-center bg-interactive hover:bg-white shadow-none hover:shadow-none hover:text-black rounded-sm",
    },
  },

  cardHeader: {
    defaultProps: {
      variant: "filled",
      color: "white",
      shadow: false,
      floated: true,
      className: "",
    },
    styles: {
      base: {
        initial: {
          position: "relative",
          backgroundClip: "bg-clip-border",
          mt: "mt-4",
          mx: "mx-4",
          borderRadius: "rounded-xl",
          overflow: "overflow-hidden",
        },
        shadow: {
          boxShadow: "shadow-none",
        },
        floated: {
          mt: "-mt-6",
        },
      },
    },
  },  

  cardBody: {
    defaultProps: {
      className: "",
    },
    styles: {
      base: {
        p: "p-4",
      },
    },
  },
  
  cardFooter: {
    defaultProps: {
      className: "",
    },
    styles: {
      base: {
        initial: {
          p: "p-4",
        },
        divider: {
          borderWidth: "border-t",
          borderColor: "border-white",
        },
      },
    },
  },
  
  input: {
    defaultProps: {
      variant: "outlined",
      size: "lg",
      color: "brown",
      className: "hover:bg-primary/15",
    },
    styles: {
      base: {
        container: {
          position: "relative",
          width: "w-full",
          minWidth: "min-w-[160px]",
        },
        input: {
          peer: "peer",
          width: "w-full",
          height: "h-full",
          bg: "bg-primary-70",
          color: "text-white",
          fontFamily: "font-sans",
          fontWeight: "font-normal",
          outline: "outline outline-0 focus:outline-0",
          disabled: "disabled:bg-black/20 disabled:text-grayscale-lucky",
        },
        label: {
          display: "flex",
          width: "w-full",
          height: "h-full",
          position: "absolute",
          left: "left-0",
          fontWeight: "font-normal",
          textOverflow: "truncate",
          color: "peer-placeholder-shown:text-white",
          lineHeight: "leading-tight peer-focus:leading-tight",
          disabled: "peer-disabled:text-grayscale-lucky peer-disabled:peer-placeholder-shown:text-grayscale-lucky",
        },
        icon: {
          display: "grid",
          placeItems: "place-items-center",
          position: "absolute",
          color: "text-white",
        },
        asterisk: {
          display: "inline-block",
          color: "text-red-500",
          ml: "ml-0.5",
        },
      },
      variants: {
        outlined: {
          base: {
            input: {
              borderWidth: "placeholder-shown:border",
              borderColor: "placeholder-shown:border-primary placeholder-shown:border-primary focus:border-brown-500",
              floated: {
                borderWidth: "border focus:border-2",
                borderColor: "border-t-transparent focus:border-t-transparent",
              },
            },
            label: {
              position: "-top-1.5",
              fontSize: "peer-placeholder-shown:text-sm",
              floated: {
                fontSize: "text-[0.875rem] peer-focus:text-[0.875rem]",
              },
              before: {
                borderColor: "peer-placeholder-shown:before:border-transparent",
                disabled: "",
              },
              after: {
                borderColor: "peer-placeholder-shown:after:border-transparent",
                disabled: "",
              },
            },
          },
          sizes: {
            lg: {
              container: {
                height: "h-12",
              },
              input: {
                fontSize: "text-md",
                px: "px-3",
                py: "py-3",
                borderRadius: "rounded-md",
              },
              icon: {
                width: "w-6",
                height: "h-6",
              },
            },
          },
          colors: {
            input: {
              brown: {
                color: "text-white",
                borderColor: "border-primary",
                borderColorFocused: "",
              },
            },
            label: {
              brown: {
                color: "text-white peer-focus:text-white",
                before: "before:border-primary peer-focus:before:border-brown-500",
                after: "after:border-primary peer-focus:after:border-brown-500",
              },
            },
          },
          error: {
            input: {
              borderColor:
                "border-red-500 placeholder-shown:border-t-red-500 placeholder-shown:border-red-500",
              borderColorFocused: "focus:border-red-500",
            },
            label: {
              color: "text-red-500 peer-focus:text-red-500 peer-placeholder-shown:text-red-500",
              before: "before:border-red-500 peer-focus:before:border-red-500",
              after: "after:border-red-500 peer-focus:after:border-red-500",
            },
          },
          success: {
            input: {
              borderColor:
                "border-green-500 placeholder-shown:border-t-green-500 placeholder-shown:border-green-500",
              borderColorFocused: "focus:border-green-500",
            },
            label: {
              color: "text-green-500 peer-focus:text-green-500 peer-placeholder-shown:text-green-500",
              before: "before:border-green-500 peer-focus:before:border-green-500",
              after: "after:border-green-500 peer-focus:after:border-green-500",
            },
          },
          shrink: {
            input: {
              borderTop: "!border-t-transparent",
            },
            label: {
              borderColor: "before:!border-primary after:!border-primary",
            },
          },
        },
      },
    },
  },

  select: {
    defaultProps: {
      variant: "outlined",
      color: "brown",
      size: "lg",
      label: "",
      arrow: undefined,
      value: undefined,
      onChange: undefined,
      selected: undefined,
      offset: 4,
      autoHeight: false,
      lockScroll: false,
      labelProps: {
        className: "text-white",
      },
      menuProps: {},
      className: "hover:bg-primary/15",
      disabled: false,
      containerProps: undefined,
    },
    styles: {
      base: {
        container: {
          position: "relative",
          width: "w-full",
          minWidth: "min-w-[180px]",
        },
        select: {
          peer: "peer",
          width: "w-full",
          height: "h-full",
          bg: "bg-primary-70",
          color: "text-white",
          fontFamily: "font-sans",
          fontWeight: "font-normal",
          textAlign: "text-left",
          outline: "outline outline-0 focus:outline-0",
          disabled: "disabled:bg-black/20 disabled:text-grayscale-lucky",
        },
        arrow: {
          initial: {
            display: "grid",
            placeItems: "place-items-center",
            position: "absolute",
            top: "top-2/4",
            right: "right-2",
            pt: "pt-px",
            width: "w-5",
            height: "h-5",
            color: "text-white",
            transform: "rotate-0 -translate-y-2/4",
            transition: "transition-all",
          },
          active: {
            transform: "rotate-180",
            mt: "mt-px",
          },
        },
        label: {
          display: "flex",
          width: "w-full",
          height: "h-full",
          userSelect: "select-none",
          pointerEvents: "pointer-events-none",
          position: "absolute",
          left: "left-0",
          fontWeight: "font-normal",
          transition: "transition-all",
        },
        menu: {
          width: "w-full",
          maxHeight: "max-h-96",
          bg: "bg-grayscale-deep",
          p: "p-3",
          border: "border border-primary-deep",
          borderRadius: "rounded-md",
          boxShadow: "shadow-lg shadow-black/10",
          fontFamily: "font-sans",
          fontSize: "text-base",
          fontWeight: "font-normal",
          color: "text-red-500",
          overflow: "overflow-auto",
          outline: "focus:outline-none",
        },
        option: {
          initial: {
            pt: "pt-[9px]",
            pb: "pb-2",
            px: "px-2",
            borderRadius: "rounded-md",
            lightHeight: "leading-tight",
            cursor: "cursor-pointer",
            userSelect: "select-none",
            background: "hover:bg-white focus:bg-white",
            opacity: "hover:bg-opacity-100 focus:bg-opacity-100",
            color: "hover:text-black focus:text-black",
            outline: "outline outline-0",
            transition: "transition-all",
          },
          active: {
            bg: "bg-white bg-opacity-75",
            color: "text-black",
          },
          disabled: {
            opacity: "opacity-50",
            cursor: "cursor-not-allowed",
            userSelect: "select-none",
            pointerEvents: "pointer-events-none",
          },
        },
      },
      variants: {
        outlined: {
          base: {
            select: {},
            label: {
              position: "-top-1.5",
              before: {
                content: "before:content[' ']",
                display: "before:block",
                boxSizing: "before:box-border",
                width: "before:w-2.5",
                height: "before:h-1.5",
                mt: "before:mt-[6.5px]",
                mr: "before:mr-1",
                borderRadius: "before:rounded-tl-md",
                pointerEvents: "before:pointer-events-none",
                transition: "before:transition-all",
                disabled: "peer-disabled:before:border-transparent",
              },
              after: {
                content: "after:content[' ']",
                display: "after:block",
                flexGrow: "after:flex-grow",
                boxSizing: "after:box-border",
                width: "after:w-2.5",
                height: "after:h-1.5",
                mt: "after:mt-[6.5px]",
                ml: "after:ml-1",
                borderRadius: "after:rounded-tr-md",
                pointerEvents: "after:pointer-events-none",
                transition: "after:transition-all",
                disabled: "peer-disabled:after:border-transparent",
              },
            },
          },
          sizes: {
            lg: {
              container: {
                height: "h-12",
              },
              select: {
                fontSize: "text-base",
                px: "px-3",
                py: "py-3",
                borderRadius: "rounded-[7px]",
              },
              label: {
                initial: {},
                states: {
                  close: {
                    lineHeight: "leading-[3.8]",
                  },
                  open: {
                    lineHeight: "leading-tight",
                  },
                  withValue: {
                    lineHeight: "leading-tight",
                  },
                },
              },
            },
          },
          colors: {
            select: {
              brown: {
                close: {
                  borderColor: "border-primary",
                },
                open: {
                  borderColor: "border-brown-500",
                  borderTopColor: "border-t-transparent",
                },
                withValue: {
                  borderColor: "border-primary",
                  borderTopColor: "border-t-transparent",
                },
              },
            },
            label: {
              brown: {
                close: {
                  color: "text-white",
                  before: "before:border-transparent",
                  after: "after:border-transparent",
                },
                open: {
                  color: "text-white",
                  before: "before:border-brown-500",
                  after: "after:border-brown-500",
                },
                withValue: {
                  color: "text-white",
                  before: "before:border-primary",
                  after: "after:border-primary",
                },
              },
            },
          },
          states: {
            close: {
              select: {
                borderWidth: "border",
              },
              label: {
                fontSize: "text-base",
                disabled: "peer-disabled:text-grayscale-lucky",
                before: {
                  bt: "before:border-t-transparent",
                  bl: "before:border-l-transparent",
                },
                after: {
                  bt: "after:border-t-transparent",
                  br: "after:border-r-transparent",
                },
              },
            },
            open: {
              select: {
                borderWidth: "border-2",
                borderColor: "border-t-transparent",
              },
              label: {
                fontSize: "text-[0.875rem]",
                disabled: "peer-disabled:text-transparent",
                before: {
                  bt: "before:border-t-2",
                  bl: "before:border-l-2",
                },
                after: {
                  bt: "after:border-t-2",
                  br: "after:border-r-2",
                },
              },
            },
            withValue: {
              select: {
                borderWidth: "border",
                borderColor: "border-t-transparent",
              },
              label: {
                fontSize: "text-[0.875rem]",
                disabled: "peer-disabled:text-transparent",
                before: {
                  bt: "before:border-t",
                  bl: "before:border-l",
                },
                after: {
                  bt: "after:border-t",
                  br: "after:border-r",
                },
              },
            },
          },
          error: {
            select: {
              initial: {},
              states: {
                close: {
                  borderColor: "border-red-500",
                },
                open: {
                  borderColor: "border-red-500",
                  borderTopColor: "border-t-transparent",
                },
                withValue: {
                  borderColor: "border-red-500",
                  borderTopColor: "border-t-transparent",
                },
              },
            },
            label: {
              initial: {},
              states: {
                close: {
                  color: "text-red-500",
                  before: "before:border-red-500",
                  after: "after:border-red-500",
                },
                open: {
                  color: "text-red-500",
                  before: "before:border-red-500",
                  after: "after:border-red-500",
                },
                withValue: {
                  color: "text-red-500",
                  before: "before:border-red-500",
                  after: "after:border-red-500",
                },
              },
            },
          },
          success: {
            select: {
              initial: {},
              states: {
                close: {
                  borderColor: "border-green-500",
                },
                open: {
                  borderColor: "border-green-500",
                  borderTopColor: "border-t-transparent",
                },
                withValue: {
                  borderColor: "border-green-500",
                  borderTopColor: "border-t-transparent",
                },
              },
            },
            label: {
              initial: {},
              states: {
                close: {
                  color: "text-green-500",
                  before: "before:border-green-500",
                  after: "after:border-green-500",
                },
                open: {
                  color: "text-green-500",
                  before: "before:border-green-500",
                  after: "after:border-green-500",
                },
                withValue: {
                  color: "text-green-500",
                  before: "before:border-green-500",
                  after: "after:border-green-500",
                },
              },
            },
          },
        },
      },
    },
  },

  switch: {
    defaultProps: {
      color: "indigo",
      ripple: false,
      className: "",
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      circleProps: undefined,
    },
    styles: {
      base: {
        root: {
          display: "inline-flex",
          alignItems: "items-center",
        },
        container: {
          position: "relative",
          display: "inline-block",
          width: "w-8",
          height: "h-4",
          cursor: "cursor-pointer",
          borderRadius: "rounded-full",
        },
        input: {
          peer: "peer",
          appearance: "appearance-none",
          width: "w-8",
          height: "h-4",
          position: "absolute",
          background: "bg-secondary-15",
        },
        ripple: {
          display: "inline-block",
          top: "top-2/4",
          left: "left-2/4",
          transform: "-translate-x-2/4 -translate-y-2/4",
          p: "p-5",
          borderRadius: "rounded-full",
        },
        label: {
          color: "text-white",
          fontWeight: "font-light",
          userSelect: "select-none",
          cursor: "cursor-pointer",
          mt: "mt-px",
          ml: "ml-3",
          mb: "mb-0",
        },
        disabled: {
          opacity: "opacity-50",
          pointerEvents: "pointer-events-none",
        },
      },
      colors: {
        indigo: {
          input: "checked:bg-interactive",
          circle: "peer-checked:bg-white",
          before: "peer-checked:before:bg-interactive",
        },
      },
    },
  }, 


  radio: {
    defaultProps: {
      color: "indigo",
      label: undefined,
      icon: undefined,
      ripple: false,
      className: "",
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      iconProps: undefined,
    },
    styles: {
      base: {
        input: {
          peer: "peer",
          position: "relative",
          appearance: "appearance-none",
          width: "w-5",
          height: "h-5",
          borderWidth: "border",
          borderRadius: "rounded-full",
          borderColor: "border-interactive-bright",
          cursor: "cursor-pointer",
          transition: "transition-all",
          before: {
            content: "before:content['']",
            display: "before:block",
            bg: "before:border-interactive-bright",
            width: "before:w-12",
            height: "before:h-12",
            borderRadius: "before:rounded-full",
            position: "before:absolute",
            top: "before:top-2/4",
            left: "before:left-2/4",
            transform: "before:-translate-y-2/4 before:-translate-x-2/4",
            opacity: "before:opacity-0 hover:before:opacity-10",
            transition: "before:transition-opacity",
          },
        },
        label: {
          color: "text-white",
          fontWeight: "font-light",
          userSelect: "select-none",
          cursor: "cursor-pointer",
          mt: "mt-px",
        },
        icon: {
          position: "absolute",
          top: "top-2/4",
          left: "left-2/4",
          translate: "-translate-y-2/4 -translate-x-2/4",
          pointerEvents: "pointer-events-none",
          opacity: "opacity-0 peer-checked:opacity-100",
          transition: "transition-opacity",
        },
        disabled: {
          opacity: "opacity-50",
          pointerEvents: "pointer-events-none",
        },
      },
      colors: {
        indigo: {
          color: "text-interactive",
          border: "checked:border-interactive",
          before: "checked:before:bg-interactive",
        },
      },
    },
  },


  chip: {
    defaultProps: {
      variant: "filled",
      size: "md",
      color: "",
      className: "text-center",
    },
  },  

  
}
