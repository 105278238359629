import React from 'react'

import {
  Button,
} from '@material-tailwind/react'

import ServerCard from './_components/ServerCard'


const getBot = () => {
  const newWindow = window.open(
    `${process.env.REACT_APP_BOT_INVITE}&disable_guild_select=false`,
    'popup',
    'width=600,height=900,scrollbars=no,resizable=no,return=true'
  )
  newWindow.focus()
}

const ChooseServer = () => {
  return (
    <section className='container'>

      <div className="banner-info mx-auto lg:w-4/6 text-center">
        <h4>Don't wait — Boost your server security today</h4>
        <p>Protect your Discord server with our powerful global ban utility! <br/> With over 600 flagged bad actors, adding our bot will instantly safeguard your community.</p>
          <Button onClick={() => {getBot()}} size='sm' className='button-secondary mt-4 mx-auto'>
            Protect your server
          </Button>        
      </div>

      <h1 className="mt-8">Select a server</h1>

      <ServerCard />

    </section>
  )
}

export default ChooseServer
