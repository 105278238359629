import React from 'react'

import {
  Button,
} from '@material-tailwind/react'

import iconDiscord from '../../img/icon-discord.svg'
import homeCody from '../../img/home-cody.png'
import { SeparatorCody } from '../../admin/components/ui/separatorCody'

const Hero = () => {
  return (

    <>
      <section className='container text-center'>

        <h1 className='display-hero mb-2 pt-4 lg:pt-12'>The only NFT companion you need</h1>

        <img src={homeCody} alt='TekTools' className='w-96 mt-[-20px] mx-auto' />

        <div className='grid grid-cols-4'>
          <p className='emphasis mb-6 lg:mb-10 col-span-4 md:col-start-2 md:col-span-2'>The one-of-a-kind management tool to make your NFT project safer, combining a wide range of utilities with great user experience.</p>
        </div>

        <div className='grid grid-cols-3'>
          <div className='col-span-3 md:col-start-2 md:col-span-1'>
            <Button onClick={()=>window.open("https://discord.com/api/oauth2/authorize?client_id=940332206512033894&permissions=8&scope=bot", "DiscordInvite")} fullWidth size='md' className='button-primary'>
              <img src={iconDiscord} />
              Begin now
            </Button>
          </div>
        </div>

       <SeparatorCody/>

      </section>

    </>
  )
}
export default Hero
