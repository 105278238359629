import React from 'react'
import {
    Typography,
    Button,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Avatar,
} from '@material-tailwind/react'

import {
    LifebuoyIcon,
    ServerStackIcon,
    UserCircleIcon,
    InformationCircleIcon,
} from '@heroicons/react/24/outline'

import {
    ChevronDownIcon,
    PowerIcon,
} from "@heroicons/react/24/solid";

import { FaCrown } from 'react-icons/fa'

import avatarDiscordStandard from '../../img/avatar-discord-standard.jpg'
import { handleLogout } from '../../utils/authUtils'

const ProfileMenu = ({ userData }) => {

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const closeMenu = () => setIsMenuOpen(false);

    const avatarUrl = userData?.user?.avatar
        ? `https://cdn.discordapp.com/avatars/${userData.user.id}/${userData.user.avatar}`
        : `${avatarDiscordStandard}`;

    const products = [
        {
            name: 'Account',
            description: '',
            href: '/account',
            icon: UserCircleIcon,
        },
        {
            name: 'My servers',
            description: '',
            href: '/select-server',
            icon: ServerStackIcon,
        },
        {
            name: 'Pro',
            description: '',
            href: '#',
            icon: FaCrown,
        },
        {
            name: 'Support',
            description: '',
            href: '#',
            icon: LifebuoyIcon,
        },
        {
            name: 'Docs',
            description: '',
            href: 'https://docs.tektools.app/',
            icon: InformationCircleIcon,
        },
        {
            name: 'Sign out',
            description: '',
            onclick: handleLogout,
            icon: PowerIcon,
        },
    ]

    return (
        <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
            {/* Avatar button + User menu */}
            <MenuHandler>
                <Button
                    variant="text"
                    color="white"
                    className="flex items-center gap-1 rounded-full py-0 pr-2 pl-0.5 lg:ml-auto"
                >
                    <Avatar
                        variant="circular"
                        size="sm"
                        className="border border-grayscale-bright p-0.5"
                        src={avatarUrl}
                    />
                    <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`h-4 w-4 transition-transform ${isMenuOpen ? "rotate-180 " : ""}`}
                        inert="true"
                    />
                </Button>
            </MenuHandler>
            <MenuList>
                {products.map((item, key) => {
                    const isLastItem = key === products.length - 1;
                    return (
                        <MenuItem
                            key={item.name}
                            onClick={() => {
                                if (item.onclick) {
                                    item.onclick();
                                }
                                if (item.href) {
                                    window.location = item.href;
                                }
                                closeMenu();
                            }}
                            className={`${isLastItem ? "border-none hover:bg-accent-red/50 focus:bg-accent-red/50" : ""}`}
                        >
                            {React.createElement(item.icon, {
                                className: `h-5 w-5 ${isLastItem ? "text-red-500" : ""}`,
                                strokeWidth: 2,
                            })}
                            <Typography
                                as="span"
                                variant="paragraph"
                                className="font-normal uppercase"
                                color={isLastItem ? "red" : "inherit"}
                            >
                                {item.name}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Menu>
    );
}

export default ProfileMenu;