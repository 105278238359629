import { Button, Card, Input, IconButton } from '@material-tailwind/react'
import { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import { fetchRoles } from '../../../utils/rolesUtils'
import { SelectDefault } from '../../components/ui/select'

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

export const RolePerQty = ({ RoleQuantity, listRoleQuantity }) => {
  const guildId = localStorage.getItem('guildId')
  const [selectedRole, setSelectedRole] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [roles, setRoles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rolesPerQty, setRolesPerQty] = useState(
    RoleQuantity
      ? RoleQuantity.map(({ RoleName, NumberOfNfts, RoleId }) => ({
        RoleName,
        NumberOfNfts,
        RoleId,
      }))
      : []
  )

  const handleValueQuantity = (e) => {
    setQuantity(Number(e.target.value))
  }

  const handleAddRoleQty = () => {
    const errors = []
    if (!selectedRole) {
      errors.push('Select a role')
    }
    if (quantity <= 0) {
      errors.push('Quantity must be greater than 0')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      return
    }

    const existingRoleIndex = rolesPerQty.findIndex(
      (role) => role.RoleId === selectedRole.value
    )
    if (existingRoleIndex !== -1) {
      toast.error('Opss, role already added with a different quantity')
      return
    }

    const newRoleQty = {
      RoleName: selectedRole.label,
      NumberOfNfts: quantity,
      RoleId: selectedRole.value,
    }
    setRolesPerQty([...rolesPerQty, newRoleQty])
  }

  const handleDeleteRoleQty = (index) => {
    const updatedRolesPerQty = rolesPerQty.filter((_, i) => i !== index)
    setRolesPerQty(updatedRolesPerQty)
  }

  // load roles
  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => role.name !== "@everyone") // Filtering everyone option from Roles.
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const filteredRolesPerQty = rolesPerQty?.filter((roleQty) =>
    roleQty.RoleName.toLowerCase().includes(searchQuery.toLowerCase())
  )

  useEffect(() => {
    listRoleQuantity(rolesPerQty)
  }, [rolesPerQty, listRoleQuantity])

  return (
    <>
      {/* Add Role Quantity */}
      <div className='grid lg:grid-cols-3 gap-6 items-center'>

        {/* QUANTITY REWARD */}
        <div className="relative w-full">
          <Input type="number" value={quantity} label="Quantity" onChange={handleValueQuantity} />
          <div className="absolute right-2 top-2 flex gap-1">
            <IconButton size="sm" className="rounded" onClick={() => setQuantity((cur) => (cur === 0 ? 0 : cur - 1))}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4">
                <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
              </svg>
            </IconButton>
            <IconButton size="sm" className="rounded" onClick={() => setQuantity((cur) => cur + 1)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4"
              >
                <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
              </svg>
            </IconButton>
          </div>
        </div>

        {/* ROLE */}
        <SelectDefault
          options={dropdownRoles}
          onChange={(selectedOption) => {
            setSelectedRole(selectedOption)
          }}
          placeholder="Select Role"
          label="Role"
          value={selectedRole}
          isLoading={isLoading}
        />

        {/* ADD BUTTON */}
        <div>
          <Button size="sm" className="button-primary" onClick={handleAddRoleQty} >
            add role
          </Button>
        </div>

      </div>

      <div className="flex flex-col mt-4">


        {/* Role Grid Search */}
        <Input icon={<MagnifyingGlassIcon />} label="Filter by role" placeholder="Enter a role" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value.toLowerCase())} />

        {/* Role Grid */}
        <Card className="bg-transparent min-h-28 overflow-y-auto scrollbar-thin scrollbar-track-primary-deep scrollbar-thumb-grayscale-lucky rounded-sm pt-2">

          <table className="w-full text-left mt-1">
            <thead className="text-sm uppercase bg-white/10">
              <tr>
                <th scope="col" className="w-44">
                  Quantity
                </th>
                <th scope="col">
                  Role
                </th>
                <th scope="col" className="w-10"></th>
              </tr>
            </thead>
            {filteredRolesPerQty?.map((roleQty, index) => (
              <tbody key={index} className={!filteredRolesPerQty ? 'hidden' : ''}>
                <tr>
                  <td>
                    {roleQty.NumberOfNfts}
                  </td>
                  <td>
                    {roleQty.RoleName}
                  </td>
                  <td className="action-cell">
                    <IconButton onClick={() => handleDeleteRoleQty(index)} className="w-8 h-8 ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </IconButton>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>

          {!filteredRolesPerQty.length > 0 && (
            <div className="text-center text-accent-gold text-sm p-2">
              No rule found.
            </div>
          )}

        </Card>

      </div>
    </>
  )
}
