import React, { useState } from 'react'
import Switch from '../components/ui/switch.js'
import CreateTicket from './CreateTicket.js'

const TicketSystem = () => {
  return (
    <section className="container">
        <h1>Ticket System</h1>
        <CreateTicket />
    </section>
  )
}

export default TicketSystem
