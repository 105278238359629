import React from 'react'

import {
  Button
} from '@material-tailwind/react'

import logo from '../../img/tektools-logotype.svg'
import iconDiscord from '../../img/icon-discord.svg'
import iconXTwitter from '../../img/icon-x-twitter.svg'

const Footer = () => {
  return (
    <footer className='border-t border-primary bg-background mt-20'>

      <div className='container py-8'>

        <div className='grid grid-cols-1 gap-4 lg:grid-cols-3 xl:grid-cols-4 items-center'>

          <div className='xl:col-span-2 mx-auto lg:mx-0'>
            <a href='/'>
              <span className='sr-only'>TekTools</span>
              <img id='tektools-logo-footer' src={logo} alt='TekTools' />
            </a>
          </div>
          
            <Button onClick={()=>window.open("https://discord.gg/tekros", "Tekros")} size='sm' className='button-secondary w-auto'>
              <img src={iconDiscord} />
              Join our Discord server
            </Button>

            <Button onClick={()=>window.open("https://x.com/tektoolsapp", "X-Twitter")} size='sm' className='button-secondary w-auto'>
              <img src={iconXTwitter} />
              Follow us on X/Twitter
            </Button>

          <small className='text-center my-6 lg:my-0 lg:mt-8 lg:text-left'>Copyright © Tekros/TekTools.app 2023-{(new Date().getFullYear())}</small>
        </div>

      </div>

    </footer>
  )
}

export default Footer
