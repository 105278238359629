import { HttpService } from './axios-httpService'

export const fetchStorage = async (guildId) => {
  try {
    const response = await HttpService.getStorageByGuildId(guildId)
    return response
  } catch (error) {
    throw new Error('Failed to fetch storage')
  }
}
