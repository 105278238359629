const ROUTES = {
    DASHBOARD: {
        HOME: '/dashboard',
        ANTIBOT_MEMBERS: '/dashboard/antibotmembers',
        HOLDER_VERIFICATION: '/dashboard/holderverification',
        STAKING_SETUP: '/dashboard/stakingsetup',
        TOKEN_CREATION: '/dashboard/tokencreation',
        REVENUE_SHARING: '/dashboard/revenuesharing',
        DECENTRALIZED_STORAGE: '/dashboard/decentralizestorage',
        VOTING_SYSTEM: '/dashboard/votingsystem',
        PASSWORD_LOCK: '/dashboard/passwordlock',
        WALLET_MANAGEMENT: '/dashboard/walletmanagement',
        TICKET_SYSTEM: '/dashboard/ticket',
        GIVEAWAY: '/dashboard/giveaway',
        ROLE_SYSTEM: '/dashboard/rolesystem',
        PREMIUM: '/dashboard/premium',
    },
};

export default ROUTES;