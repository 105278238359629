import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { getStaking } from '../../utils/stakingUtils'
import { Loading } from '../components/ui/loading'
import { SelectDefault } from '../components/ui/select'
import { SpecificRewards } from './_components/SpecificRewards'
import TokenOption from './_components/TokenOption'

export default function CreateStakingSetup() {
  const navigate = useNavigate()
  const guildId = localStorage.getItem('guildId')
  const [stakingConfig, setStakingConfig] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [selectedCollection, setSelectedCollection] = useState([])
  const [stakedToken, setStakedToken] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingConstructor, setLoadingConstructor] = useState(false)
  const [loadComponent, setLoadComponent] = useState(false)
  const [rewardsPerDays, setRewardsPerDays] = useState(0)
  const [minimumWithdrawBalance, setMinimumWithdrawBalance] = useState(0)
  const [minimumWithdrawDays, setMinimumWithdrawDays] = useState(0)

  // load staking config
  const loadStakingConfig = () => {
    setLoadingConstructor(true)
    getStaking(guildId)
      .then((data) => {
        setStakingConfig(data[0])
        setLoadingConstructor(false)
      })
      .catch((error) => {
        console.error('Error loading staking config:', error)
      })
  }

  useEffect(() => {
    if (!stakingConfig) {
      loadStakingConfig()
    }
  }, [stakingConfig])


  // load roles
  const [roles, setRoles] = useState([])
  useEffect(() => {
    if (!roles || roles.length === 0) {
      fetchRoles(guildId).then((data) => {
        setRoles(data)
      })
    }
  }, [])

  // load channels
  const [channels, setChannels] = useState([])
  useEffect(() => {
    if (!channels || channels.length === 0) {
      fetchChannels(guildId).then((data) => {
        setChannels(data)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  useEffect(() => {
    if (stakingConfig && !loadComponent) {
      setSelectedRole(dropdownRoles.find(
        (ch) => ch.value === stakingConfig?.RoleAbleToStake[0]
      ) || null)
      setSelectedChannel(dropdownChannels.find(
        (ch) => ch.value === stakingConfig?.StakingChannelId
      ) || null)
      setSelectedCollection(
        (stakingConfig?.StakedCollection || []).map((collection) => ({
          value: [
            {
              CollectionAddress: collection.CollectionMint,
              VerifiedAddress: collection.CollectionId,
            },
          ],
          label: collection.CollectionName,
          reward: collection.TraitRewards || [],
        }))
      )
      setRewardsPerDays(stakingConfig?.TotalRewardPerDay)
      setMinimumWithdrawBalance(stakingConfig?.WithdrawMinimumBalance)
      setMinimumWithdrawDays(stakingConfig?.WithdrawMinimumDays)
      setStakedToken(
        {
          value: stakingConfig?.TokenAddress,
          label: stakingConfig?.TokenName,
          image: stakingConfig?.TokenImage,
        }
      )
      setLoadComponent(true)
    }
  }, [stakingConfig, loadComponent, dropdownRoles, dropdownChannels, stakedToken])

  const handleRoleChange = (value) => {
    setSelectedRole(value)
  }

  const handleChannelChange = (value) => {
    setSelectedChannel(value)
  }

  const SaveStakingSetup = () => {
    setLoading(true)
    const errors = []
    if (!selectedRole) {
      errors.push('Please select a role.')
    }
    if (!selectedChannel) {
      errors.push('Please select a channel.')
    }
    if (!selectedCollection || selectedCollection.length === 0) {
      errors.push('Please select a collection.')
    }
    if (!stakedToken) {
      errors.push('Please select a staked token.')
    }
    if (errors.length > 0) {
      toast.error(errors.join('\n'), { style: { whiteSpace: 'pre-line' } })
      setLoading(false)
      return
    }
    const infoCreateStaking = {
      guildId: localStorage.getItem('guildId'),
      eligibleRole: [selectedRole.value],
      tokenName: stakedToken.label,
      tokenAddress: stakedToken.value,
      tokenImage: stakedToken.image ? stakedToken.image : '',
      stakingChannelId: selectedChannel.value,
      dailyRewardAmount: rewardsPerDays,
      minimumWithdrawalBalance: minimumWithdrawBalance,
      minimumWithdrawalDays: minimumWithdrawDays,
      stakedCollections: selectedCollection.map((collection) => ({
        collectionName: collection.label,
        collectionMint: collection.value[0].CollectionAddress,
        collectionId: collection.value[0].VerifiedAddress,
        traitRewards: (collection.reward || []).map((reward) => ({
          type: reward.type || reward.TraitType,
          value: reward.value || reward.TraitValue,
          reward: reward.reward || reward.TraitReward,
        })),
      })),
    }

    HttpService.postCreateStaking(infoCreateStaking)
      .then((response) => {
        toast.success(stakingConfig ? 'Staking setup updated successfully!' : 'Staking setup created successfully!')
        setLoading(false)
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
        setLoading(false)
      })
  }

  const incrementQuantityRewardsPerDays = () => {
    setRewardsPerDays((prev) => prev + 1)
  }

  const decrementQuantityRewardsPerDays = () => {
    setRewardsPerDays((prev) => (prev > 1 ? prev - 1 : 0))
  }

  const handleQuantityChangeRewardsPerDays = (e) => {
    const value = Number.parseInt(e.target.value)
    setRewardsPerDays(value > 0 ? value : 0)
  }

  const incrementQuantityMinimumWithdrawBalance = () => {
    setMinimumWithdrawBalance((prev) => prev + 1)
  }

  const decrementQuantityMinimumWithdrawBalance = () => {
    setMinimumWithdrawBalance((prev) => (prev > 1 ? prev - 1 : 0))
  }

  const handleQuantityChangeMinimumWithdrawBalance = (e) => {
    const value = Number.parseInt(e.target.value)
    setMinimumWithdrawBalance(value > 0 ? value : 0)
  }

  const incrementQuantityMinimumWithdrawDays = () => {
    setMinimumWithdrawDays((prev) => prev + 1)
  }

  const decrementQuantityMinimumWithdrawDays = () => {
    setMinimumWithdrawDays((prev) => (prev > 1 ? prev - 1 : 0))
  }

  const handleQuantityChangeMinimumWithdrawDays = (e) => {
    const value = Number.parseInt(e.target.value)
    setMinimumWithdrawDays(value > 0 ? value : 0)
  }

  // constructor html
  if (loadingConstructor) {
    return <Loading />
  }


  return (
    <>
      <section className="flex flex-col relative">
        <div className="container mx-auto flex flex-col gap-y-5 py-5 h-full ">
          {/* section 1 */}
          <div className="flex gap-x-5">
            {/* Select Role */}
            <div className='w-full z-40'>
              <SelectDefault
                label="Select the role that can stake."
                placeholder="Select Role"
                options={dropdownRoles}
                onChange={handleRoleChange}
                value={selectedRole}
              />
            </div>
            {/* Select Channel */}
            <div className='w-full z-20'>
              <SelectDefault
                label="Select a channel where the staking info will be."
                placeholder="Select Channel"
                options={dropdownChannels}
                onChange={handleChannelChange}
                value={selectedChannel}
              />
            </div>

          </div>
          {/* section 2 */}
          <div className="flex flex-col gap-y-8 z-40">
            <TokenOption
              loadTokenValue={stakedToken}
              onStakedTokensChange={setStakedToken}
            />
            <div className="w-full flex flex-col lg:flex-row gap-5 items-end ">
              {/* Rewards per days */}
              <div className='flex flex-col justify-end h-full shadow-lg  w-full'>
                <span className="text-base font-semibold">
                  Define how much a holder gets rewarded per day of staking
                </span>
                <div className="flex items-center h-full transition duration-300 rounded-lg shadow pt-px bg-second border border-third">
                  <input
                    type="number"
                    min={1}
                    value={rewardsPerDays}
                    onChange={handleQuantityChangeRewardsPerDays}
                    className={` h-full text-white  first-of-type:pl-4 border-none bg-transparent min-w-16 w-full outline-none`}
                  />
                  <div className="flex flex-col h-full">
                    <button
                      onClick={incrementQuantityRewardsPerDays}
                      className="bg-main hover:bg-mainHover p-1 w-6 flex items-center justify-center rounded-t-md"
                    >
                      <BiPlus />
                    </button>
                    <hr className="border-second" />
                    <button
                      onClick={decrementQuantityRewardsPerDays}
                      className="bg-main hover:bg-mainHover p-1 w-6  flex items-center justify-center rounded-b-md"
                    >
                      <BiMinus />
                    </button>
                  </div>
                </div>
              </div>
              {/* Minimum withdraw balance */}
              <div className='flex flex-col justify-end h-full shadow-lg  w-full'>
                <span className="text-base font-semibold">
                  Define a minimum withdraw balance
                </span>
                <div className="flex items-center h-full transition duration-300 rounded-lg shadow pt-px bg-second border border-third">
                  <input
                    type="number"
                    min={1}
                    value={minimumWithdrawBalance}
                    onChange={handleQuantityChangeMinimumWithdrawBalance}
                    className={` h-full text-white  first-of-type:pl-4 border-none bg-transparent min-w-16 w-full outline-none`}
                  />
                  <div className="flex flex-col h-full">
                    <button
                      onClick={incrementQuantityMinimumWithdrawBalance}
                      className="bg-main hover:bg-mainHover p-1 w-6 flex items-center justify-center rounded-t-md"
                    >
                      <BiPlus />
                    </button>
                    <hr className="border-second" />
                    <button
                      onClick={decrementQuantityMinimumWithdrawBalance}
                      className="bg-main hover:bg-mainHover p-1 w-6  flex items-center justify-center rounded-b-md"
                    >
                      <BiMinus />
                    </button>
                  </div>
                </div>
              </div>
              {/* Minimum amount of days */}
              <div className='flex flex-col justify-end h-full shadow-lg  w-full'>
                <span className="text-base font-semibold">
                  Define a minimum withdraw amount of days
                </span>
                <div className="flex items-center h-full transition duration-300 rounded-lg shadow pt-px bg-second border border-third">
                  <input
                    type="number"
                    min={1}
                    value={minimumWithdrawDays}
                    onChange={handleQuantityChangeMinimumWithdrawDays}
                    className={` h-full text-white  first-of-type:pl-4 border-none bg-transparent min-w-16 w-full outline-none`}
                  />
                  <div className="flex flex-col h-full">
                    <button
                      onClick={incrementQuantityMinimumWithdrawDays}
                      className="bg-main hover:bg-mainHover p-1 w-6 flex items-center justify-center rounded-t-md"
                    >
                      <BiPlus />
                    </button>
                    <hr className="border-second" />
                    <button
                      onClick={decrementQuantityMinimumWithdrawDays}
                      className="bg-main hover:bg-mainHover p-1 w-6  flex items-center justify-center rounded-b-md"
                    >
                      <BiMinus />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="w-full border-third my-5" />
          {/* section 3 */}
          <div className="flex flex-col gap-3 w-full">
            <SpecificRewards
              collectionsRewards={selectedCollection}
              onSpecificRewardsChange={(newCollections) => setSelectedCollection(newCollections)}
            />
          </div>
          {/* section 4 - button */}
          <div className="flex justify-end pt-10 pb-20">
            <Button
              variant="text"
              color="red"
              className="mr-5"
              onClick={() => navigate('/dashboard')}
            >
              Cancel
            </Button>
            <Button
              color="deep-purple"
              className="bg-main"
              size="lg"
              onClick={SaveStakingSetup}
            >
              Save
            </Button>
          </div>
        </div>
      </section>
      {loading &&
        <div className='fixed top-0 right-0 w-full h-full flex justify-center items-center bg-dark/40 border-third z-50'>
          <Loading />
        </div>
      }
    </>
  )
}
