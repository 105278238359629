import {
    Button,
} from '@material-tailwind/react'

import WelcomeCody from '../../img/welcome-cody.png'
import ROUTES from '../../utils/routes'

const Welcome = () => {

    const redirectTo = (url) => {
        window.location.href = url;
    }

    return (
        <section className="flex justify-center items-center h-5/6">

            <div className='banner-standard flex flex-col items-center text-center gap-2 !m-[48px]'>

                <div className=''>
                    <img src={WelcomeCody} alt="Welcome to Tektools" className='h-auto w-full max-w-[200px]' />
                </div>


                <h4>Welcome to Tektools</h4>

                <p className='max-w-[380px] mb-4'>This is the manager area. Use the  menu to navigate, or choose one of the most common actions below:</p>

                <div className='grid lg:grid-cols-3 gap-4 w-full'>

                    <Button size="sm" className="button-secondary" onClick={() => redirectTo(ROUTES.DASHBOARD.ANTIBOT_MEMBERS)}>
                        Anti bot members
                    </Button>
                    <Button size="sm" className="button-secondary" onClick={() => redirectTo(ROUTES.DASHBOARD.TICKET_SYSTEM)}>
                        Ticket system
                    </Button>
                    <Button size="sm" className="button-secondary" onClick={() => redirectTo(ROUTES.DASHBOARD.GIVEAWAY)}>
                        Giveaway
                    </Button>

                </div>


            </div>

        </section>)
}

export default Welcome