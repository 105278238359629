import {
  Input,
  Select,
  Option,
  Radio,
  Switch,
  Textarea,
  Button,
} from '@material-tailwind/react'

import React, { useState, useEffect } from 'react'
import { fetchRoles } from '../../../utils/rolesUtils'
import { SeparatorDefault } from '../../components/ui/separator'
import { SelectDefault } from '../../components/ui/select'

import iconNFT from '../../../img/icon-nft.svg'

const NftSetup = ({
  onRoleSelect,
  onQuantitySelect,
  onCreatorAddress,
  onCollectionName,
  onCollectionAddress,
  onHashlist,
  onHashlistFile,
  onSkipRole,
  onBackButtonClick,
  onNextButtonClick,
}) => {
  const guildId = localStorage.getItem('guildId')
  const [value, setValue] = useState(1)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [creatorAddress, setCreatorAddress] = useState('')
  const [collectionName, setCollectionName] = useState('')
  const [collectionAddress, setCollectionAddress] = useState('')
  const [isCollectionAddress, setIsCollectionAddress] = useState(true)
  const [isHashlist, setIsHashlist] = useState(false)
  const [isSkipRole, setIsSkipRole] = useState(false)
  const [hashlistFile, setHashlistFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // load roles
  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const roleOptions = roles
    ?.filter((role) => role.name !== "@everyone") // Filtering everyone option from Roles.
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  useEffect(() => {
    onQuantitySelect(value)
  }, [value, onQuantitySelect])

  useEffect(() => {
    onRoleSelect(selectedRole)
  }, [selectedRole, onRoleSelect])

  useEffect(() => {
    onCollectionName(collectionName)
  }, [collectionName, onCollectionName])

  useEffect(() => {
    onCreatorAddress(creatorAddress)
  }, [creatorAddress, onCreatorAddress])

  useEffect(() => {
    onCollectionAddress(collectionAddress)
  }, [collectionAddress, onCollectionAddress])

  useEffect(() => {
    onHashlist(isHashlist)
  }, [isHashlist, onHashlist])

  useEffect(() => {
    onSkipRole(isSkipRole)
  }, [isSkipRole, onSkipRole])

  useEffect(() => {
    onHashlistFile(hashlistFile)
  }, [hashlistFile, onHashlistFile])

  const handleHashlistUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      setHashlistFile(file)
    }
  }
  const handleCollectionAddress = () => {
    setIsCollectionAddress(cur => !cur)
    setCreatorAddress('')
    setCollectionAddress('')
  };

  return (
    <>

      <Button size='sm' className="button-secondary mb-4" onClick={onBackButtonClick}>
        &lt;&nbsp;Back
      </Button>

      <div className='flex flex-row gap-2'>

        <h1>New NFT Collection</h1>

        <img src={iconNFT} className='h-10 w-10 filter-accent-blue' />

      </div>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">

        {/* Collection Name */}
        <div>
          <Input value={collectionName} label="NFT collection name" placeholder="Example: My Cool NFT Collection" onChange={(e) => setCollectionName(e.target.value)} />
        </div>

      </div>

      <SeparatorDefault />

      <h3>Address type</h3>

      {/* Address type selector */}
      <div className="flex flex-col">
        <Radio name="addressType" label="Collection" checked={isCollectionAddress} onChange={handleCollectionAddress} />
        <Radio name="addressType" label="Creator" checked={!isCollectionAddress} onChange={handleCollectionAddress} />
      </div>

      <div className='grid mt-6'>

        {/* Input address */}
        <div>
          {isCollectionAddress
            ? (<Textarea color="brown" label="Collection address (*)" className='custom-textarea' placeholder="Example: 0x1234567890123456789012345678901234567890" value={collectionAddress}
              onChange={(e) => setCollectionAddress(e.target.value)} />)
            : (<Textarea color="brown" label="Creator's  address (*)" className='custom-textarea' placeholder="Example: 0x1234567890123456789012345678901234567890" value={creatorAddress}
              onChange={(e) => setCreatorAddress(e.target.value)} />)
          }
        </div>

      </div>

      <SeparatorDefault />

      <h3>Hashlist</h3>

      <p>If you want, you can upload a hashlist in JSON, CSV or XML format.</p>

      <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-4'>

        <div>
          <input
            type="file"
            id="fileInput"
            className='input-file-upload'
            onChange={handleHashlistUpload}
          />
 
          {hashlistFile && <small className='field-description'>Loaded file: {hashlistFile.name}</small>}

        </div>

      </div>

      <SeparatorDefault />

      <h3>Role</h3>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">

        {/* Role */}
        <div>
          <SelectDefault
            label="Role"
            value={selectedRole}
            options={roleOptions}
            isLoading={isLoading}
            onChange={(selectedOption) => {
              setSelectedRole(selectedOption)
            }}
          />
        </div>

      </div>

      <div className='mt-4'>
        <Switch
          label="Skip role"
          checked={isSkipRole}
          onChange={() => setIsSkipRole(!isSkipRole)}
        />
      </div>

      <div className='grid grid cols-1 lg:grid-cols-12 mt-10 pb-10'>
        <Button className="button-primary lg:col-span-4" onClick={onNextButtonClick} >
          Next
        </Button>
      </div>
    </>
  )
}

export default NftSetup
