import {
  Button,
} from '@material-tailwind/react'

import WelcomeCody from '../../../img/welcome-cody.png'

const Premium = () => {

  return (
    <section className="flex justify-center items-center h-5/6">

      <div className='banner-standard flex flex-col items-center text-center gap-2 !m-[48px]'>

        <div className=''>
          <img src={WelcomeCody} alt="Welcome to Tektools" className='h-auto w-full max-w-[200px]' />
        </div>

        <h4>You've found a premium feature!</h4>
        <h5>Upgrade to enjoy its full potential.</h5>

        <p className='max-w-[380px] mb-4'>
          <ul>
            <li>- Holder Verification</li>
            <li>- Staking System</li>
            <li>- Airdrop System</li>
            <li>- Revenue Sharing System</li>
          </ul>
        </p>

        <div className='grid lg:grid-cols-3 gap-4 w-full'>

          <Button size="sm" className="button-secondary" onClick={() =>
            window.open('https://discord.com/invite/tekros', '_blank')
          }>
            Upgrade your Workspace
          </Button>

        </div>

      </div>

    </section>)
}

export default Premium
