import React, { useState, useEffect } from 'react'
import { fetchRoles } from '../../../utils/rolesUtils'
import { SelectDefault } from '../../components/ui/select'

import {
  Textarea,
  Input,
  IconButton,
  Button,
} from '@material-tailwind/react'

import iconToken from '../../../img/icon-token.svg'

const TokenSetup = ({
  onRoleSelect,
  onQuantitySelect,
  onTokenAddress,
  onCollectionName,
  onSkipRole,
  onBackButtonClick,
  onNextButtonClick
}) => {
  const guildId = localStorage.getItem('guildId')
  const [quantity, setQuantity] = useState(1)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [tokenAddress, setTokenAddress] = useState('')
  const [collectionName, setCollectionName] = useState('')
  const [isSkipRole, setIsSkipRole] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // load roles
  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const roleOptions = roles
    ?.filter((role) => role.name !== "@everyone") // Filtering everyone option from Roles.
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  useEffect(() => {
    onQuantitySelect(quantity)
  }, [quantity, onQuantitySelect])

  useEffect(() => {
    onRoleSelect(selectedRole)
  }, [selectedRole, onRoleSelect])

  useEffect(() => {
    onCollectionName(collectionName)
  }, [collectionName, onCollectionName])

  useEffect(() => {
    onTokenAddress(tokenAddress)
  }, [tokenAddress, onTokenAddress])

  useEffect(() => {
    onSkipRole(isSkipRole)
  }, [isSkipRole, onSkipRole])

  const handleValueQuantity = (e) => {
    setQuantity(Number(e.target.value))
  }

  return (
    <>

      <Button size='sm' className="button-secondary mb-4" onClick={onBackButtonClick}>
        &lt;&nbsp;Back
      </Button>

      <div className='flex flex-row gap-2'>

      <h1>New Token Collection</h1>

        <img src={iconToken} className='h-10 w-10 filter-accent-green' />

      </div>      


      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">

        {/* Collection Name */}
        <div>
          <Input value={collectionName} label="Token collection name" placeholder="Example: My Cool Token Collection" onChange={(e) => setCollectionName(e.target.value)} />
        </div>

        {/* Address */}
        <div className='lg:col-span-2 xl:col-span-3'>
          <Textarea color="brown" label="Token collection address (*)" value={tokenAddress} onChange={(e) => setTokenAddress(e.target.value)} className='custom-textarea' placeholder="Example: 0x1234567890123456789012345678901234567890" />
        </div>

      </div>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-6">

        {/* Quantity input token */}
        <div className="relative w-full">
          <Input type="number" value={quantity} onChange={handleValueQuantity} label="Minimum quantity" />
          <div className="absolute right-2 top-2 flex gap-1">
            <IconButton size="sm" className="rounded" onClick={() => setQuantity((cur) => (cur === 1 ? 1 : cur - 1))}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4">
                <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
              </svg>
            </IconButton>
            <IconButton size="sm" className="rounded" onClick={() => setQuantity((cur) => cur + 1)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4"
              >
                <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
              </svg>
            </IconButton>
          </div>
        </div>

      </div>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-6">

        {/* Quantity input role */}

        <SelectDefault
          isLoading={isLoading}
          options={roleOptions}
          onChange={(selectedOption) => {
            setSelectedRole(selectedOption)
          }}
          placeholder="Select Role"
          label="Role"
          value={selectedRole}
        />

      </div>

      <div className='grid grid cols-1 lg:grid-cols-12 mt-10 pb-10'>
        <Button className="button-primary lg:col-span-4" onClick={onNextButtonClick} >
          Next
        </Button>
      </div>
    </>
  )
}

export default TokenSetup
