import { HttpService } from './axios-httpService'

export const fetchTraits = async (collectionMint, creatorAddress, guildId) => {
  const payload = {
    guildId,
    collectionMint: collectionMint != null ? collectionMint : '',
    creatorAddress: creatorAddress != null ? creatorAddress : '',
  }
  try {
    const response = await HttpService.postTraits(payload)
    return response.data
  } catch (error) {
    throw new Error('Error fetching traits')
  }
}


