import { Button, Spinner } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'
import { HttpService } from '../../../utils/axios-httpService'
import { getToken } from '../../../utils/tokenUtils'
import SectionTokenCreation from '../../TokenCreation/SectionTokenCreation'

const TokenOption = ({ onStakedTokensChange, loadTokenValue }) => {
  const guildId = localStorage.getItem('guildId')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tokenOptions, setTokenOptions] = useState([])
  const [stakedTokens, setStakedTokens] = useState([])

  const [inputValue, setInputValue] = useState(null)
  const [tokenInfo, setTokenInfo] = useState(null)


  const [isLoading, setIsLoading] = useState(false)

  const loadTokenOptions = () => {
    setIsLoading(true)
    getToken(guildId).then((data) => {
      setTokenOptions(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (!tokenOptions || tokenOptions.length === 0) {
      loadTokenOptions()
    }
  }, [])

  const listTokens = tokenOptions
    ? tokenOptions
      .filter((token) => token.TokenAddress !== null)
      .filter((token) => {
        return !inputValue || token.TokenAddress !== inputValue.value;
      })
      .map((token) => ({
        value: token.TokenAddress,
        label: token.TokenName,
        image: token.TokenImageUrl,
      }))
    : [];

  useEffect(() => {
    if (listTokens.length > 0 && loadTokenValue && inputValue === null) {
      const tokenIdentifier = loadTokenValue.value ? loadTokenValue.value : loadTokenValue
      const initialToken = listTokens.find(token => token.value === tokenIdentifier) || null
      setInputValue(initialToken)
    }
  }, [loadTokenValue, listTokens, inputValue])

  const handleTokenInfoChange = (info) => {
    setTokenInfo(info)
  }

  const [modalToken, setModalToken] = useState(false)
  const [loadingNewToken, setLoadingNewToken] = useState(false)

  const SaveToken = async () => {
    setLoadingNewToken(true)
    if (!tokenInfo || Object.values(tokenInfo).some((value) => !value)) {
      toast.error('Please fill in all fields before saving the token.')
      setLoadingNewToken(false)
      return
    }
    try {
      const response = await HttpService.postCreateToken(tokenInfo)
      toast.success('Token created successfully!')
      setLoadingNewToken(false)
      setModalToken(false)
      setTokenInfo(null)
      loadTokenOptions()
      handleTokenChange(response.data.tokenAddress)
    } catch (error) {
      toast.error('Error creating token!')
      setLoadingNewToken(false)

    }
  }

  const handleTokenChange = (selectedOption) => {
    setInputValue(selectedOption)
    setStakedTokens(selectedOption)
  }

  useEffect(() => {
    onStakedTokensChange(inputValue)
  }, [inputValue, onStakedTokensChange])

  const handleCloseModal = () => {
    setModalToken(false)
    setTokenInfo(null)
  }

  const options = [
    ...listTokens,
    // {
    //   value: 'new',
    //   label: (
    //     <div
    //       className="flex items-center justify-center h-10 border-b border-t border-third 
    //       -ml-5 bg-basic hover:bg-main hover:font-bold transition-all duration-300"
    //       onClick={() => setModalToken(true)}
    //     >
    //       Create New Token
    //     </div>
    //   ),
    // },
  ]

  return (
    <>
      <div className="flex flex-col gap-y-2 w-full h-full relative ">
        <div className="flex flex-col gap-y-5">
          {/* Token Address */}
          <div className="w-full">
            <div className="w-full flex flex-col justify-end">
              <span className="text-base font-semibold">Select or create a new custom token for your collection or if you prefer use an existing token like sol, usdc, bonk</span>
              {modalToken && (
                <div className="flex w-screen h-screen top-0 right-0 bg-dark/40 border-third fixed z-40 ">

                  <div className="w-[900px] h-fit flex flex-col bg-basic rounded-lg border border-third top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 relative items-center justify-center">
                    <div className="w-full flex justify-end pb-5">
                      <Button
                        color="red"
                        variant="text"
                        onClick={handleCloseModal}
                      >
                        X
                      </Button>
                    </div>
                    <div className='w-full z-50'>
                      <SectionTokenCreation
                        onTokenInfoChange={handleTokenInfoChange}
                      />
                    </div>

                    <div className="w-full flex justify-end p-5">
                      <Button
                        color="red"
                        variant="text"
                        onClick={() => {
                          setModalToken(false)
                          setTokenInfo(null)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="deep-purple"
                        variant="filled"
                        className="bg-main"
                        onClick={SaveToken}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  {loadingNewToken && (
                    <div className="fixed w-full h-full  top-0 right-0 bg-dark/40 border-third flex justify-center items-center">
                      <Spinner />
                    </div>
                  )}
                </div>
              )}
              <div className="w-full flex justify-end gap-2">
                <div className='w-full h-full flex justify-end bg-second rounded-lg border border-third z-10'>
                  <CreatableSelect
                    // isLoading={inputValue === null}
                    // isDisabled={inputValue === null}
                    onSelectResetsInput={true}
                    closeMenuOnSelect={true}
                    options={options}
                    className="basic-multi-select text-white"
                    classNamePrefix="select overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic text-white"
                    onChange={handleTokenChange}
                    placeholder="Select your token or enter your address."
                    formatCreateLabel={(inputValue) => `Search by address "${inputValue}"`}
                    value={inputValue}
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        backgroundColor: '#2C2F48',
                        color: '#fff',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#B420FF #2C2F48',
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                        height: 'auto',
                        maxHeight: '100%',
                        color: '#fff',
                        alignItems: 'center',
                      }),
                      container: (provided, state) => ({
                        ...provided,
                        color: '#fff',
                        margin: 0,
                        padding: 0,
                        border: 0,
                        width: '100%',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#fff',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        height: 'auto',
                        maxHeight: '100%',
                        maxWidth: '100%',
                        backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                        color: '#fff',
                        borderRadius: 8,
                        borderColor:
                          state.isFocused || state.isHovered
                            ? '#B420FF'
                            : '#2C2F48',
                        boxShadow:
                          state.isFocused || state.isHovered
                            ? '0 0 0 0.5px #B420FF'
                            : 'none',
                        paddingLeft: '1rem',
                        transition: 'border-color 0.2s ease',
                        '&:hover': {
                          borderColor: '#B420FF',
                          boxShadow: '0 0 0 0.5px #B420FF',
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? '#B420FF'
                          : state.isFocused
                            ? '#393D5E'
                            : '#2C2F48',
                        color: state.isFocused ? '#B420FF' : '#fff',
                        cursor: 'pointer',
                        maxWidth: '97%',
                        padding: '0.5rem 1rem',
                        margin: '0.2rem 0',
                        marginLeft: '0.5rem',
                        marginRight: '0.5rem',
                        borderRadius: '8px',
                        '&:hover': {
                          backgroundColor: '#393D5E',
                          paddingLeft: '1.5rem',
                          transition: 'all 0.3s ease',
                        },
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: '#B420FF',
                        color: '#fff',
                        borderRadius: 4,
                        padding: '0.2rem 0.5rem',
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        color: '#fff',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#808080',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#fff',
                      }),
                    }}
                  />
                </div>
                <div className='w-fit flex justify-end'>
                  <Button
                    color="purple"
                    variant="filled"
                    className="bg-main w-full p-3 whitespace-nowrap"
                    onClick={() => {
                      setModalToken(true)
                    }}
                  >
                    Create New Token
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TokenOption
