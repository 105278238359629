import { Button, Card, CardBody, Spinner } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import NullServerImage from '../../../img/TektoolsFaceWhite.png'
import { HttpService } from '../../../utils/axios-httpService'
import { Loading } from '../../components/ui/loading'

const ServerCard = () => {
  const navigate = useNavigate()
  const [servers, setServers] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingSelectedServer, setLoadingSelectedServer] = useState(false)
  const [serverName, setServerName] = useState('')
  const [noServers, setNoServers] = useState(true)

  //Call API to get all servers
  const fetchMyServers = () => {
    HttpService.getGuilds()
      .then((response) => {
        setServers(response.data)
        if (response.data.length > 0) setNoServers(false)
      })
      .catch((error) => {
        console.error('Error fetching server list:', error)
      })
  }

  useEffect(() => {
    if (servers && servers.length === 0) {
      fetchMyServers()
    }
  }, [])

  //Save server in local storage and navigate to dashboard
  const handleBotton = (server) => {
    localStorage.setItem('myServers', JSON.stringify(servers))
    if (server.isBotInstalled) {
      setServerName(server.name)
      setLoadingSelectedServer(true)
      localStorage.setItem('guildId', server.id)
      navigate('/dashboard')
      setLoadingSelectedServer(false)
    } else {
      const newWindow = window.open(
        `${process.env.REACT_APP_BOT_INVITE}&guild_id=${server.id}&disable_guild_select=false`,
        'popup',
        'width=600,height=900,scrollbars=no,resizable=no,return=true'
      )
      newWindow.focus()
    }
  }

  const noManageServers = () => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    if (loading) return <Loading />
    return (
      <div className="banner-standard mx-auto lg:w-4/6 text-center">
        <p>Looks like you don't have any servers. Please create a Discord server, then come back here and <strong>refresh the page.</strong></p>
        <br/>
        <p>if you want to manage your wallets, go to your <a href={() => navigate('/account')} className="text-interactive-bright">Account settings.</a></p>
      </div>
    )
  }

  if (noServers) return noManageServers()
  return (
    <>
      {loadingSelectedServer && (
        <div className="absolute top-0 left-0 w-screen h-screen z-40 flex items-center justify-center gap-x-2">
          <span>{`Loading ${serverName}...`}</span>
          <Loading />
        </div>
      )}

      {/* Card grid */}
      <div className='grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4'>

        {servers?.map((server, index) => (

          <Card className="unclickable-card !p-0 server-card" key={index}>

            {/* Card Header - Avatar/Icon */}
            <div className="relative h-[220px] flex items-center justify-center overflow-hidden">
              {server.icon === null ? (
                <div
                  className="server-blurred-bg-image"
                  style={{
                    backgroundImage: `url(${NullServerImage})`,
                  }}
                ></div>
              ) : (
                <div
                  className="server-blurred-bg-image"
                  style={{
                    backgroundImage: `url(https://cdn.discordapp.com/icons/${server.id}/${server.icon})`,
                  }}
                ></div>
              )}
              {server.icon === null ? (
                <div className="server-icon rounded-full bg-primary flex items-center justify-center">
                  <span className="text-white text-2xl">
                    {server.name.substring(0, 6)}
                  </span>
                </div>
              ) : (
                <img
                  className="server-icon rounded-full"                
                  src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}`}
                  alt={server.name}
                />
              )}
            </div>

            {/* Card Body - Server Name and type permission */}
            <CardBody>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2">
                  <p className="truncate emphasis">
                    {server.name}
                  </p>
                  <small>
                    {server.owner ? 'Owner' : 'Bot Master'}
                  </small>
                </div>
                {/* Action Button */}
                <div className="">
                  <Button fullWidth size='md' className='button-primary'
                    onClick={() => handleBotton(server)}
                  >
                    {server.isBotInstalled ? 'GO' : 'Setup'}
                  </Button>
                </div>
              </div>
            </CardBody>
            
          </Card>

        ))}
      </div>
    </>
  )
}

export default ServerCard
