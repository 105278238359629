import React, { useState } from 'react'
import { Select, Option } from "@material-tailwind/react";
import ROUTES from "../../utils/routes";

export function SelectServerDropdown() {
    //TODO:improve how we parse this object.
    const servers = Array.isArray(JSON.parse(localStorage.getItem('myServers')))
        ? JSON.parse(localStorage.getItem('myServers'))?.filter((server) => server.isBotInstalled)
        : [];

    const selectedServerId = localStorage.getItem('guildId');
    const handleChangeServer = (selectedServer) => {
        localStorage.setItem('guildId', selectedServer.id)
        // setServerSelected(selectedServer.id)
        window.location.href = ROUTES.DASHBOARD.HOME;
    }

    return (
        <div>
            <Select
                size="lg"
                label="Current server"
                value={selectedServerId}
                selected={(element) =>
                    element &&
                    React.cloneElement(element, {
                        disabled: true,
                        className:
                            "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                    })
                }
            >
                {servers.map((server, index) => (
                    <Option key={index} value={server.id} className="flex items-center gap-1" onClick={() => handleChangeServer(server)}>
                        {server.icon ? (
                            <img
                                src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}`}
                                alt={server.name}
                                className="h-6 w-6 rounded-full object-cover"
                            />
                        ) : (
                            <div className="w-6 h-6 rounded-full border border-secondary flex items-center justify-center text-[11px] tracking-tighter">
                                {server.name.substring(0, 3)}
                            </div>
                        )}
                        {server.name}
                    </Option>
                ))}
            </Select>
        </div>
    );
}