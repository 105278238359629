import { Button, IconButton, Input } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { HttpService } from '../../../utils/axios-httpService'

import iconXTwitter from '../../../img/icon-x-twitter.svg'
import iconTelegram from '../../../img/icon-telegram.svg'

const Social = () => {
  const [socialAccount, setSocialAccount] = useState([])
  const [telegram, setTelegram] = useState('')
  const [x, setX] = useState('')
  const [configX, setConfigX] = useState(false)
  const [configTelegram, setConfigTelegram] = useState(false)

  const getSocial = () => {
    HttpService.getMe().then((response) => {
      setSocialAccount(response)
      setTelegram(response.data.telegramId)
      setX(response.data.twitterHandle)
    })
  }

  useEffect(() => {
    if (socialAccount.length === 0) {
      getSocial()
    }
  }, [])

  const saveSocial = async () => {
    const payload = {
      twitterHandle: x,
      telegramId: telegram,
    }
    HttpService.patchUpdateSocial(payload)
      .then((response) => {
        toast.success('Social updated successfully!')
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
      })
    if (x !== '') setConfigX(false)
    if (telegram !== '') setConfigTelegram(false)
  }

  return (
    <div className="flex flex-col lg:flex-row items-start gap-4">

      {/* X/Twitter Account */}

        <div className='profile-x-twitter grow lg:grow-0 w-full lg:w-auto lg:basis-1/2 xl:basis-1/3 p-4 bg-black/30 rounded-lg relative overflow-hidden'>

          <small className='text-grayscale-bright'>X/Twitter profile</small>

          <div className='flex flex-row items-center gap-2 h-16'>

            <img src={iconXTwitter} className='filter-white w-8 h-8' />

            <div className='flex flex-row items-center'>

              <div>{/* Account name */}
                <p className={`${configX ? 'hidden' : ''}`}>
                  {x}
                  {x === '' ||
                    (!x && (
                      <>
                        (Not informed)
                      </>
                    ))}
                </p>
              </div>

              <div className={`ml-4 ${configX ? 'hidden' : ''}`}>{/* Edit button */}

                <IconButton
                  className="w-8 h-8"
                  onClick={() => {
                    setConfigX(!configX)
                    if (configTelegram) setConfigTelegram(false)
                  }}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                  </svg>
                </IconButton>

              </div>

              <div className={`${configX ? 'flex flex-row' : 'hidden'}`}>{/* Account input + OK button */}

                <div className='mr-2 w-[188px]'>
                  <Input
                    value={x}
                    label="Your profile"
                    placeholder="Ex.: @tekrosunlimited"
                    onChange={(e) => setX(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        saveSocial()
                      }
                    }} 
                  />
                </div>

                <Button size="md" className="button-primary" onClick={saveSocial}>
                  OK
                </Button>

              </div>

            </div>

          </div>
        </div>


      {/* Telegram Account */}

        <div className='profile-telegram grow lg:grow-0 w-full lg:w-auto lg:basis-1/2 xl:basis-1/3 p-4 bg-light-blue-300/30 rounded-lg relative overflow-hidden'>

          <small className='text-grayscale-bright'>Telegram profile</small>

          <div className='flex flex-row items-center gap-2 h-16'>

            <img src={iconTelegram} className='filter-white w-8 h-8' />

            <div className='flex flex-row items-center'>

              <div>{/* Account name */}
                <p className={`${configTelegram ? 'hidden' : ''}`}>
                  {telegram}
                  {telegram === '' ||
                    (!telegram && (
                      <span className="text-main font-bold uppercase text-sm">
                        Not informed
                      </span>
                    ))}
                </p>
              </div>

              <div className={`ml-4 ${configTelegram ? 'hidden' : ''}`}>{/* Edit button */}

                <IconButton
                  className="w-8 h-8"
                  onClick={() => {
                    setConfigTelegram(!configTelegram)
                    if (configX) setConfigX(false)
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                  </svg>
                </IconButton>

              </div>

              <div className={`${configTelegram ? 'flex flex-row' : 'hidden'}`}>{/* Account input + OK button */}

                <div className='mr-2 w-[188px]'>
                  <Input
                    value={telegram}
                    label="Your profile"
                    placeholder="Ex.: @tekrosunlimited"
                    onChange={(e) => setTelegram(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        saveSocial()
                      }
                    }}
                  />
                </div>

                <Button size="md" className="button-primary" onClick={saveSocial}>
                  OK
                </Button>

              </div>

            </div>

          </div>

        </div>


    </div>
  )
}

export default Social
