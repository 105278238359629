import React from 'react'
import Header from '../../components/Header/Header'
import SetupAccount from './_components/SetupAccount'
import { DrawerMenu } from '../../components/Main/DrawerMenu'

const Account = () => {
  const [open, setOpen] = React.useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  return (
    <>
      <DrawerMenu isOpen={open} onCloseClick={closeDrawer} />
      <Header onDrawerClick={openDrawer} />
      <SetupAccount />
    </>
  )
}

export default Account
