import {
  Button,
  IconButton,
  Checkbox,
  Select,
  Option,
  Input,
  Textarea,
} from '@material-tailwind/react'

import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { SelectDefault } from '../components/ui/select'

import { SeparatorDefault } from '../components/ui/separator'

import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css'
import 'react-multi-date-picker/styles/colors/purple.css'

const SetupGiveaway = ({ setIsDrawerOpen }) => {
  const guildId = localStorage.getItem('guildId')
  const userId = JSON.parse(localStorage.getItem('userData')).user.id
  const [isLoading, setIsLoading] = useState(false)
  const [rewardDescription, setRewardDescription] = useState('')
  const [numberOfWinners, setNumberOfWinners] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const handleNumberOfWinners = (e) => {
    setNumberOfWinners(Number(e.target.value))
  }

  const [selectedDurationOption, setSelectedDurationOption] = useState(null)
  const CUSTOM_VALUE = 'Custom'
  const customOptionSelected = selectedDurationOption?.value === CUSTOM_VALUE

  const durationOptions = [
    { label: '30min', value: '30min' },
    { label: '1h', value: '1h' },
    { label: '6h', value: '6h' },
    { label: '12h', value: '12h' },
    { label: '1d', value: '1d' },
    { label: '7d', value: '7d' },
    { label: '30d', value: '30d' },
    { label: CUSTOM_VALUE, value: CUSTOM_VALUE },
  ]

  const calculateEndDate = (duration) => {
    const now = new Date()
    const end = new Date()

    //TODO: We should consider use day.js library to avoid date issues.
    switch (duration) {
      case '30min':
        end.setMinutes(now.getMinutes() + 30)
        break
      case '1h':
        end.setHours(now.getHours() + 1)
        break
      case '6h':
        end.setHours(now.getHours() + 6)
        break
      case '12h':
        end.setHours(now.getHours() + 12)
        break
      case '1d':
        end.setDate(now.getDate() + 1)
        break
      case '7d':
        end.setDate(now.getDate() + 7)
        break
      case '30d':
        end.setDate(now.getDate() + 30)
        break
      default:
        return
    }

    return { start: now, end }
  }

  const handleCheckboxChange = (parSelectedDurationOption) => {
    setSelectedDurationOption(parSelectedDurationOption)

    if (!(parSelectedDurationOption?.value)) {
      setStartDate(null)
      setEndDate(null)
      return;
    }

    if (parSelectedDurationOption.value !== CUSTOM_VALUE) {
      const dates = calculateEndDate(parSelectedDurationOption.value)
      setStartDate(dates.start)
      setEndDate(dates.end)
    }
  }

  const createNewDrawing = () => {
    if (rewardDescription.trim() === '') {
      toast.error('Please enter a reward description.')
      return;
    }
    if (!selectedDurationOption) {
      toast.error('Please select a duration.')
    }

    const endDateObj = endDate instanceof Date ? endDate : new Date(endDate)
    const roleId =
      selectedRole?.value ||
      roles.find((role) => role.name === '@everyone')?.id ||
      ''
    const infoDrawing = {
      guildId,
      userId,
      channelId: selectedChannel?.value || '',
      roleId,
      rewardDescription,
      numberOfWinners,
      startDate: startDate.toISOString(),
      endDate: endDateObj.toISOString(),
    }

    HttpService.postCreateGiveaway(infoDrawing)
      .then((response) => {
        setSelectedChannel(null)
        setSelectedRole(null)
        setRewardDescription('')
        setNumberOfWinners(1)
        setSelectedDurationOption(null)
        setStartDate(null)
        setEndDate(null)
        toast.success('Giveaway created successfully!')
      })
      .catch((error) => {
        toast.error('Opss, something gone wrong.')
      })
  }

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setIsDrawerOpen(false)
      }
    }

    document.addEventListener('keydown', handleEscKey)

    // Limpeza do event listener quando o componente for desmontado
    return () => {
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [setIsDrawerOpen])


  const customStartDateHandler = (parDate, parOptions) => {
    debugger;
    console.log(parDate);
  }

  return (
    <>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">

        <div>

          <SelectDefault
            value={selectedChannel}
            onChange={(e) => setSelectedChannel(e)}
            options={dropdownChannels}
            placeholder="Select channel"
            label="Channel (*)"
          />
          <small className='field-description'>
            This is the channel where Giveaway will happen
          </small>
        </div>

        <div>

          <SelectDefault
            value={selectedRole}
            onChange={(e) => setSelectedRole(e)}
            options={dropdownRoles}
            placeholder="Select role"
            label="Role"
          />
          <small className='field-description'>
            This will be the Giveaway's participating role
          </small>

        </div>

        <div className='lg:col-span-2 xl:col-span-3'>
          <Textarea
            color="brown"
            value={rewardDescription}
            onChange={(e) => setRewardDescription(e.target.value)}
            label="Reward description (*)"
            placeholder='Enter reward description'
            className='custom-textarea' />
        </div>

      </div>


      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-6">

        {/* Number of Winners */}
        <div>

          <div className="relative w-full">
            <Input type="number" value={numberOfWinners} onChange={handleNumberOfWinners} label="Number of winners (*)" />
            <div className="absolute right-2 top-2 flex gap-1">
              <IconButton size="sm" className="rounded" onClick={() => setNumberOfWinners((cur) => (cur === 0 ? 0 : cur - 1))}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4">
                  <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                </svg>
              </IconButton>
              <IconButton size="sm" className="rounded" onClick={() => setNumberOfWinners((cur) => cur + 1)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4"
                >
                  <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                </svg>
              </IconButton>
            </div>
          </div>
        </div>

      </div>

      <SeparatorDefault />

      <h3>Reward config</h3>      

      <div className="grid lg:grid-cols-3 gap-4 mt-6">

        <div>
          <SelectDefault
            placeholder="Select duration"
            label="Reward duration"
            options={durationOptions}
            onChange={(selectedOption) => {
              handleCheckboxChange(selectedOption)
            }}
            value={selectedDurationOption}
            isLoading={isLoading}
          />
        </div>

        {/*  TODO: WE SHOULD REVIEW THESE COMPONENTS THEY DON'T WORK AS EXPECTED.  */}
        {(customOptionSelected &&
          (<>

            <div className='mt-[-8px]'>

              <div className="custom-input-label flex flex-row">
                <div>
                  &nbsp;
                </div>
                <div className='custom-input-label-text'>
                  Duration starts in
                  <div className='label-border'></div>
                </div>
                <div>
                  &nbsp;
                </div>
              </div>

              <div className="">
                <DatePicker
                  className="rmdp-prime bg-dark purple"
                  value={startDate}
                  onChange={customStartDateHandler}
                  minDate={new Date()}
                  format="MM/DD/YYYY, HH:mm"
                  style={{
                    height: '48px',
                  }}
                  containerStyle={{
                    width: '100%',
                  }}
                  plugins={[<TimePicker hideSeconds />]}
                />
              </div>
            </div>

            <div className='mt-[-8px]'>

              <div className="custom-input-label flex flex-row">
                <div>
                  &nbsp;
                </div>
                <div className='custom-input-label-text'>
                  Duration ends in
                  <div className='label-border'></div>
                </div>
                <div>
                  &nbsp;
                </div>
              </div>

              <DatePicker
                className="rmdp-prime bg-dark purple"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                minDate={startDate}
                format="MM/DD/YYYY, HH:mm"
                style={{
                  height: '48px',
                }}
                containerStyle={{
                  width: '100%',
                }}
                plugins={[<TimePicker hideSeconds />]}
              />
            </div>

          </>))}

      </div>

      <div className='grid grid cols-1 lg:grid-cols-12 mt-10 pb-10'>
        <Button onClick={createNewDrawing} className="button-primary lg:col-span-4" >
          Save
        </Button>
      </div>

    </>
  )
}

export default SetupGiveaway
