import React from "react";
import {
    List,
    ListItem,
    ListItemSuffix,
    Chip,
    Drawer,
} from "@material-tailwind/react";
import logo from '../img/tektools-logotype.svg'
import dataSidebar from "./Sidebar/dataSidebar";
import { SelectServerDropdown } from "./ChooseServer/SelectServerDropdown";
import { FaCrown } from 'react-icons/fa'
import ROUTES from "../utils/routes";

const SideBarItems = ({ filteredData }) => {
    //TODO:improve how we parse this object.
    const servers = Array.isArray(JSON.parse(localStorage.getItem('myServers')))
        ? JSON.parse(localStorage.getItem('myServers'))?.filter((server) => server.isBotInstalled)
        : [];

    const currentGuildId = localStorage.getItem('guildId')
    const currentGuild = servers.find((guild) => guild.id === currentGuildId)

    const urlToRedirect = (data) => {
        if(!data.premium) return data.link;
        return (currentGuild?.paidAccount === true && data.premium === true)
            ? data.link
            : ROUTES.DASHBOARD.PREMIUM;
    }

    return (
        <>
            {/* Sidebar's envelope background color and right border style are in the container (Admin/Main.js) */}

            {/* Sticky top */}
            <div className="sticky top-0 border border-t-0 border-r-0 border-l-0 border-b-primary/20 py-6 bg-gradient-to-r from-[#1F1C1C] to-[#413A39]">
                <div className='flex flex-row items-center gap-1 mx-4 mb-4'>
                    <a href="/dashboard">
                        <span className="sr-only">TekTools</span>
                        <img id="tektools-logo" src={logo} alt="TekTools" />
                    </a>
                    <div>
                        <Chip
                            value='Beta'
                            className='badge-accent-blue'
                        />
                    </div>

                </div>

                <div className="w-full px-4 z-50 pt-3">
                    <SelectServerDropdown />
                </div>

            </div>

            <List>

                {filteredData.map((data, index) => (
                    <a href={urlToRedirect(data)} key={index} className="group">
                        <ListItem>
                            {data.name}
                            <ListItemSuffix>
                                {data.premium && (
                                    <div className="bg-orange-50/10 group-hover:bg-black rounded-full px-2 py-1">
                                        <FaCrown
                                            size={14}
                                            className="text-xs text-orange-200"
                                        />
                                    </div>
                                )}
                            </ListItemSuffix>
                        </ListItem>
                    </a>
                ))}

            </List>
        </>
    )
}

const AdminSidebar = ({ isMobile, isDrawerOpen, onCloseDrawerClick }) => {
    const filteredData = dataSidebar.filter(
        (item) =>
            localStorage.getItem('guildId') === '1216871846067179620' || item.enable
    );

    return (
        <>
            {(isMobile
                ? (<Drawer open={isDrawerOpen} onClose={onCloseDrawerClick} overlay={isMobile} className="overflow-y-auto">
                    <SideBarItems filteredData={filteredData} />
                </Drawer>)
                : (<SideBarItems filteredData={filteredData} />)
            )}
        </>
    );
}

export default AdminSidebar;