import {
  Button,
  Input,
  Textarea,
  Spinner,
  Switch,
  IconButton
} from '@material-tailwind/react'

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { getCollectionById } from '../../utils/collectionUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { SelectDefault } from '../components/ui/select'
import { RolePerAttibute } from './_components/RolePerAttibute'
import { RolePerQty } from './_components/RolePerQty'
import { SeparatorDefault } from '../components/ui/separator'

import iconNFT from '../../img/icon-nft.svg'
import iconToken from '../../img/icon-token.svg'

const EditHolderVerification = () => {
  const guildId = localStorage.getItem('guildId')
  const { id } = useParams()
  const [collectionData, setCollectionData] = useState(null)
  const [roles, setRoles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rolesPerAtt, setRolesPerAtt] = useState([])
  const [rolesPerQty, setRolesPerQty] = useState([])
  const [listing, setListing] = useState(false)
  const [sales, setSales] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const navigate = useNavigate()
  const roleOptions = roles
    ?.filter((role) => role.name !== "@everyone") // Filtering everyone option from Roles.
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const initialHolderRole =
    roleOptions.find((option) => option.value === collectionData?.HolderRole) ||
    null
  const [holderRole, setHolderRole] = useState(initialHolderRole)

  useEffect(() => {
    if (collectionData) {
      setListing(collectionData.Listing?.IsEnabled || false)
    }
  }, [collectionData])

  useEffect(() => {
    if (collectionData) {
      setSales(collectionData.Sales?.IsEnabled || false)
      setQuantity(collectionData.MinimumQuantity)
    }
  }, [collectionData])

  // load roles
  useEffect(() => {
    if (roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  // load collection data
  useEffect(() => {
    getCollectionById(id, guildId)
      .then((data) => {
        setCollectionData(data)
      })
      .catch((error) => {
        console.error('Error fetching collection data:', error)
      })
  }, [id])

  const handleRoleChange = (selectedOption) => {
    setHolderRole(selectedOption)
  }

  const handleRolesPerAttUpdate = (updatedRolesPerAtt) => {
    setRolesPerAtt(updatedRolesPerAtt)
  }

  const handleRolesPerQtyUpdate = (updatedRolesPerQty) => {
    setRolesPerQty(updatedRolesPerQty)
  }

  const handleValueQuantity = (e) => {
    setQuantity(Number(e.target.value))
  }

  const saveClick = async () => {
    const updateCollection = {
      DisplayName: collectionData.DisplayName,
      VerifiedAddress: collectionData.VerifiedAddress,
      CollectionMint: collectionData.CollectionMint,
      MinimumQuantity: quantity,
      HolderRole:
        holderRole === null ? initialHolderRole.value : holderRole.value,
      RolePerQuantity: rolesPerQty.map((role) => ({
        roleId: role.RoleId,
        roleName: role.RoleName,
        numberOfNfts: role.NumberOfNfts,
      })),
      RolePerAttribute: rolesPerAtt.map((role) => ({
        roleId: role.RoleId,
        roleName: role.RoleName,
        attributeName: role.AttributeName,
        attributeValue: role.AttributeValue,
      })),
      ...(collectionData.IsToken === false && {
        Sales: {
          IsEnabled: sales,
        },
        Listing: {
          IsEnabled: listing,
        },
      }),
    }
    HttpService.patchCollectionNotificationInfo(guildId, id, updateCollection)
      .then((response) => {
        toast.success('Collection updated successfully!');
        setTimeout(() => {
          window.location.href = '/dashboard/holderverification'
        }, 1500);
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
      })
  }

  const handleSalesChange = () => {
    setSales(!sales)
  }

  const handleListingChange = () => {
    setListing(!listing)
  }

  return (
    <>
      {collectionData ? (
        <section className="container">

          <Button size='sm' className="button-secondary mb-4" onClick={() => navigate(-1)}>
            &lt;&nbsp;Back
          </Button>

          <div className='flex flex-row gap-2'>

            <h1>
              {collectionData
                ? `Edit ${collectionData.IsToken ? 'Token' : 'NFT'} Collection`
                : 'Loading...'}
            </h1>

            {
              (collectionData === null
                ? (<></>)
                : collectionData.IsToken === true
                  ? (<img src={iconToken} className='h-10 w-10 filter-accent-green' />)
                  : (<img src={iconNFT} className='h-10 w-10 filter-accent-blue' />)
              )
            }

          </div>


          <h3>Name, role and addresses</h3>

          {/* info collection */}

          <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-6 items-center'>
            <div>
              <Input value={collectionData.DisplayName || collectionData.CollectionName || collectionData.CollectionDisplayName} label="Collection Name" placeholder="Example: My Collection" readOnly={true} />
            </div>
            <div>
              <SelectDefault
                options={roleOptions}
                label="Holder Role"
                value={holderRole === null ? initialHolderRole : holderRole}
                onChange={handleRoleChange}
                isLoading={isLoading}
              />
            </div>

            {/* Collection Address */}
            {collectionData.CollectionMint && (
              <div className='lg:col-span-2 xl:col-span-3'>
                <Textarea color="brown" label="Mint Address" value={collectionData.CollectionMint} readOnly={true} className='custom-textarea' />
              </div>
            )}

            {/* Creator Address */}
            {collectionData.VerifiedAddress && (
              <div className='lg:col-span-2 xl:col-span-3'>
                <Textarea color="brown" label="Creator Address" value={collectionData.VerifiedAddress} readOnly={true} className='custom-textarea' />
              </div>
            )}

            {/* Token Address */}
            {collectionData.TokenAddress && (
              <div className='lg:col-span-2 xl:col-span-3'>
                <Textarea color="brown" label="Token Address" value={collectionData.TokenAddress} readOnly={true} className='custom-textarea' />
              </div>
            )}

          </div>

          {/* Listing and sales */}
          {collectionData.IsToken === false && (
            <>

              <SeparatorDefault/>

              <h3>Listing and sales</h3>

              <div>
                <Switch
                  label="Enable listings"
                  checked={listing}
                  onChange={handleListingChange}
                />
              </div>
              <div className='pt-4'>
                <Switch
                  label="Enable sales"
                  checked={sales}
                  onChange={handleSalesChange}
                />
              </div>

            </>
          )}

          {(collectionData?.IsToken === true) && (
            <>

              <SeparatorDefault />

              <h3>Token Minimum quantity</h3>

              {/* Quantity input token */}
              <div className='grid lg:grid-cols-3 gap-4 items-center'>
                <div className="relative w-full">
                  <Input type="number" value={quantity} onChange={handleValueQuantity} label="Minimum quantity" />
                  <div className="absolute right-2 top-2 flex gap-1">
                    <IconButton size="sm" className="rounded" onClick={() => setQuantity((cur) => (cur === 1 ? 1 : cur - 1))}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4">
                        <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                      </svg>
                    </IconButton>
                    <IconButton size="sm" className="rounded" onClick={() => setQuantity((cur) => cur + 1)}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4"
                      >
                        <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                      </svg>
                    </IconButton>
                  </div>
                </div>
              </div>
            </>
          )}

          <SeparatorDefault />

          <h3>Roles by quantity</h3>
          <RolePerQty
            RoleQuantity={collectionData.RolePerQuantity}
            listRoleQuantity={handleRolesPerQtyUpdate}
          />

          {(!collectionData.IsToken ||
            collectionData.IsToken === false) && (
              <>

                <SeparatorDefault />

                <h3>Roles by type and value</h3>
                <RolePerAttibute

                  RoleAttribute={collectionData.RolePerAttribute}
                  creatorAddress={collectionData.VerifiedAddress}
                  collectionMint={collectionData.CollectionMint}
                  listRoleAttribute={handleRolesPerAttUpdate}
                />

              </>
            )}

          <div className='grid grid cols-1 lg:grid-cols-12 mt-10 pb-10'>
            <Button onClick={saveClick} className="button-primary lg:col-span-4" >
              Save
            </Button>
          </div>


        </section>

      ) : (
        // Loading Spinner
        <div className="h-full w-full flex justify-center items-center">
          <Spinner className="w-12 h-12 text-secondary-bright" />
        </div>
      )}
    </>
  )
}

export default EditHolderVerification
