import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FullyProtectedRoute from './utils/FullyProtectedRoute '
import { isAuthenticated } from './utils/authUtils'
import './App.css'
import { ToastContainer } from 'react-toastify'
import Account from './account/Account/Account'
import Premium from './account/Account/User/Premium'
import AntiBotMembers from './admin/AntiBotMembers/AntiBotMembers'
import ChooseMain from './admin/ChooseServer/Main'
import DecentralizedStorage from './admin/DecentralizedStorage/DecentralizedStorage'
import Giveaway from './admin/Giveaway/Giveaway'
import EditHolderVerification from './admin/HolderVerification/EditHolderVerification'
import HolderVerification from './admin/HolderVerification/HolderVerification'
import AdminMain from './admin/Main'
import NotFound from './admin/NotFound/NotFound'
import PasswordLock from './admin/PasswordLock/PasswordLock'
import RevenueSharing from './admin/RevenueSharing/RevenueSharing'
import RoleSystem from './admin/RoleSystem/RoleSystem'
import StakingSetup from './admin/StakingSetup/StakingSetup'
import TicketSystem from './admin/TicketSystem/TicketSystem'
import TokenCreation from './admin/TokenCreation/TokenCreation'
import VotingSystem from './admin/VotingSystem/VotingSystem'
import WalletManagement from './admin/WalletManagement/WalletManagement'
import Welcome from './admin/Welcome/Welcome'
import ErrorPage from './components/Error/Error'
import Login from './components/Login/Login'
import Main from './components/Main/Main'
import { setupAxiosInterceptors } from './utils/axios-httpService'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from "@material-tailwind/react";
import { customTheme } from "./custom-theme"

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />
}

function App() {
  setupAxiosInterceptors()

  return (
    <ThemeProvider value={customTheme}>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route
            path="/account"
            element={<ProtectedRoute element={<Account />} />}
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/select-server"
            element={<ProtectedRoute element={<ChooseMain />} />}
          />
          <Route
            path="/dashboard"
            element={<FullyProtectedRoute element={<AdminMain />} />}
          >
            <Route
              index={true}
              element={<FullyProtectedRoute element={<Welcome />} />}
            />
            <Route
              path="antiBotMembers"
              element={<FullyProtectedRoute element={<AntiBotMembers />} />}
            />
            <Route
              path="holderverification"
              element={<FullyProtectedRoute element={<HolderVerification />} />}
            />
            <Route
              path="holderverification/:id"
              element={
                <FullyProtectedRoute element={<EditHolderVerification />} />
              }
            />
            <Route
              path="stakingsetup"
              element={<FullyProtectedRoute element={<StakingSetup />} />}
            />
            <Route
              path="tokencreation"
              element={<FullyProtectedRoute element={<TokenCreation />} />}
            />
            <Route
              path="revenuesharing"
              element={<FullyProtectedRoute element={<RevenueSharing />} />}
            />
            <Route
              path="decentralizestorage"
              element={<FullyProtectedRoute element={<DecentralizedStorage />} />}
            />
            <Route
              path="votingsystem"
              element={<FullyProtectedRoute element={<VotingSystem />} />}
            />
            <Route
              path="walletmanagement"
              element={<FullyProtectedRoute element={<WalletManagement />} />}
            />
            <Route
              path="ticket"
              element={<FullyProtectedRoute element={<TicketSystem />} />}
            />
            <Route
              path="passwordlock"
              element={<FullyProtectedRoute element={<PasswordLock />} />}
            />
            <Route
              path="giveaway"
              element={<FullyProtectedRoute element={<Giveaway />} />}
            />
            <Route
              path="rolesystem"
              element={<FullyProtectedRoute element={<RoleSystem />} />}
            />
            <Route
              path="premium"
              element={<FullyProtectedRoute element={<Premium />} />}
            />
            <Route path="not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/dashboard/not-found" />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </ThemeProvider>
  )
}

export default App
