import { Button, Card, Input, IconButton, Select, Option } from '@material-tailwind/react'
import { useEffect, useRef, useState } from 'react'

import { toast } from 'react-toastify'
import { fetchRoles } from '../../../utils/rolesUtils'
import { fetchTraits } from '../../../utils/traitsUtils'
import { SelectDefault } from '../../components/ui/select'

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'


export const RolePerAttibute = ({
  RoleAttribute,
  creatorAddress,
  collectionAddress,
  collectionMint,
  listRoleAttribute,
}) => {
  const guildId = localStorage.getItem('guildId')
  const [roles, setRoles] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedRole, setSelectedRole] = useState(null)
  const [rolesPerAtt, setRolesPerAtt] = useState(
    RoleAttribute
      ? RoleAttribute.map(
        ({ RoleName, AttributeName, AttributeValue, RoleId }) => ({
          RoleName,
          AttributeName,
          AttributeValue,
          RoleId,
        })
      )
      : []
  )
  const [newTraits, setNewTraits] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedTraitType, setSelectedTraitType] = useState(null)
  const [selectedTraitValue, setSelectedTraitValue] = useState(null)
  const traitTypeRef = useRef(null)
  const traitValueRef = useRef(null)
  const roleRef = useRef(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => role.name !== "@everyone") // Filtering everyone option from Roles.
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  // Load traits

  const getTraits = () => {
    fetchTraits(collectionMint, creatorAddress, guildId).then((data) => {
      setNewTraits(data)
    })
  }

  useEffect(() => {
    if (newTraits?.length === 0 && (collectionMint || creatorAddress)) {
      getTraits()
    }
  }, [collectionMint, creatorAddress, guildId])

  const traitOptions = newTraits?.map((trait) => ({
    value: trait.TraitType,
    label: trait.TraitType,
  }))

  const traitValueOptions = selectedTraitType
    ? newTraits
      ?.find((trait) => trait.TraitType === selectedTraitType.value)
      ?.TraitValue?.filter(
        (value) =>
          !rolesPerAtt.some((roleAtt) => roleAtt.AttributeValue === value)
      )
      ?.map((value) => ({
        value,
        label: value,
      })) || []
    : []

  // filter roles per attribute
  const filteredRolesPerAtt = rolesPerAtt?.filter(
    (roleQty) =>
      roleQty.RoleName &&
      roleQty.RoleName.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // add
  const handleAddRoleAttribute = () => {
    const errors = []
    if (!selectedRole) {
      errors.push('Please select a role.')
    }
    if (!selectedTraitType) {
      errors.push('Please select an attribute type.')
    }
    if (!selectedTraitValue) {
      errors.push('Please select an attribute value.')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      return
    }

    const newRoleAttribute = {
      RoleId: selectedRole.value,
      RoleName: selectedRole.label,
      AttributeName: selectedTraitType.label,
      AttributeValue: selectedTraitValue.label,
    }

    const exists = rolesPerAtt.some(
      (item) =>
        item.RoleId === newRoleAttribute.RoleId &&
        item.AttributeName === newRoleAttribute.AttributeName &&
        item.AttributeValue === newRoleAttribute.AttributeValue
    )
    if (exists) {
      toast.error('This item has already been added.')
      return
    }
    const updatedRolesPerAtt = [...rolesPerAtt, newRoleAttribute]
    setRolesPerAtt(updatedRolesPerAtt)
    setSelectedRole(null)
    setSelectedTraitType(null)
    setSelectedTraitValue(null)
    traitTypeRef.current?.clearValue()
    traitValueRef.current?.clearValue()
    roleRef.current?.clearValue()
  }

  // delete
  const handleDeleteRoleQty = (index) => {
    const updatedRolesPerAtt = rolesPerAtt.filter((_, i) => i !== index)
    setRolesPerAtt(updatedRolesPerAtt)
  }

  useEffect(() => {
    listRoleAttribute(rolesPerAtt)
  }, [rolesPerAtt, listRoleAttribute])

  return (
    <>
      <div className='grid lg:grid-cols-4 gap-4 items-center'>

        {/* TYPE */}
        <div>
          <SelectDefault
            isLoading={isLoading}
            options={traitOptions}
            onChange={(selectedOption) => {
              setSelectedTraitType(selectedOption)
              setSelectedTraitValue(null)
              traitValueRef.current?.clearValue()
            }}
            placeholder="Select Type"
            label="Type"
            value={selectedTraitType}
          />
        </div>

        {/* VALUE */}
        <div>
          <SelectDefault
            isLoading={isLoading}
            options={traitValueOptions}
            isDisabled={!selectedTraitType}
            onChange={(selectedOption) => {
              setSelectedTraitValue(selectedOption)
            }}
            placeholder="Select Value"
            label="Value"
            value={selectedTraitValue}
          />
        </div>

        {/* ROLE */}
        <div>
          <SelectDefault
            isLoading={isLoading}
            options={dropdownRoles}
            onChange={(selectedOption) => {
              setSelectedRole(selectedOption)
            }}
            placeholder="Select Role"
            label="Role"
            value={selectedRole}
          />
        </div>

        {/* ADD BUTTON */}
        <div>
          <Button size="sm" className="button-primary" onClick={handleAddRoleAttribute} >
            Add role
          </Button>
        </div>

      </div>

      <div className="flex flex-col mt-4">

        {/* Role Grid Search*/}
        <Input icon={<MagnifyingGlassIcon />} label="Filter by type, value or role" placeholder="Enter a type, value or role" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value.toLowerCase())} />

        {/* Role Grid */}
        <Card className="bg-transparent min-h-28 overflow-y-auto scrollbar-thin scrollbar-track-primary-deep scrollbar-thumb-grayscale-lucky rounded-sm pt-2">

          <table className="w-full text-left mt-1">
            <thead className="text-sm uppercase bg-white/10">
              <tr>
                <th scope="col">
                  Type
                </th>
                <th scope="col">
                  Value
                </th>
                <th scope="col">
                  Role
                </th>
                <th scope="col" className="w-10"></th>
              </tr>
            </thead>
            {filteredRolesPerAtt?.map((roleQty, index) => (
              <tbody key={index} className={!filteredRolesPerAtt ? 'hidden' : ''}>
                <tr>
                  <td>
                    {roleQty.AttributeName}
                  </td>
                  <td>
                    {roleQty.AttributeValue}
                  </td>
                  <td>
                    {roleQty.RoleName}
                  </td>
                  <td className="action-cell">
                    <IconButton onClick={() => handleDeleteRoleQty(index)} className="w-8 h-8 ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </IconButton>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>

          {!filteredRolesPerAtt.length && (
            <div className="text-center text-accent-gold text-sm p-2">
              No rule found.
            </div>
          )}
        </Card>
      </div>
    </>
  )
}
