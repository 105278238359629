import { AiOutlineGift } from 'react-icons/ai'
import { AiFillDatabase, AiOutlineLogout, AiTwotoneUsb } from 'react-icons/ai'
import { BsArrowLeftRight } from 'react-icons/bs'
import { FiUserCheck } from 'react-icons/fi'
import {
  MdAssignmentAdd,
  MdHowToVote,
  MdLockOutline,
  MdOutlineAccountBalanceWallet,
  MdOutlineToken,
} from 'react-icons/md'
import { RiShieldUserLine } from 'react-icons/ri'
import ROUTES from '../../utils/routes'

const dataSidebar = [
  {
    name: 'Anti Bot Members',
    icon: <AiTwotoneUsb />,
    link: ROUTES.DASHBOARD.ANTIBOT_MEMBERS,
    // tip: 'Consectetur voluptate veniam sunt magna mollit.',
    enable: true,
    premium: false,
  },
  {
    name: 'Holder Verification',
    icon: <FiUserCheck />,
    link: ROUTES.DASHBOARD.HOLDER_VERIFICATION,
    // tip: 'Nulla excepteur Lorem ex ea commodo fugiat eu amet nisi reprehenderit esse dolor.',
    enable: true,
    premium: true,
  },
  {
    name: 'Staking Setup',
    icon: <BsArrowLeftRight />,
    link: ROUTES.DASHBOARD.STAKING_SETUP,
    // tip: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.',
    enable: false,
    premium: true,
  },
  {
    name: 'SPL - Token Creation',
    icon: <MdOutlineToken />,
    link: ROUTES.DASHBOARD.TOKEN_CREATION,
    // tip: 'Tip for Token Creation',
    enable: false,
    premium: true,
  },
  {
    name: 'Revenue Sharing',
    icon: <AiOutlineLogout />,
    link: ROUTES.DASHBOARD.REVENUE_SHARING,
    // tip: 'Tip for Revenue Sharing',
    enable: false,
    premium: true,
  },
  {
    name: 'Decentralized Storage',
    icon: <AiFillDatabase />,
    link: ROUTES.DASHBOARD.DECENTRALIZED_STORAGE,
    // tip: 'Tip for Decentralized Store',
    enable: false,
    premium: true,
  },
  {
    name: 'Voting System',
    icon: <MdHowToVote />,
    link: ROUTES.DASHBOARD.VOTING_SYSTEM,
    // tip: 'Tip for Voting System',
    enable: false,
    premium: false,
  },
  {
    name: 'Password Lock',
    icon: <MdLockOutline />,
    link: ROUTES.DASHBOARD.PASSWORD_LOCK,
    // tip: 'Tip for Password Lock',
    enable: false,
    premium: false,
  },
  {
    name: 'Wallet Management',
    icon: <MdOutlineAccountBalanceWallet />,
    link: ROUTES.DASHBOARD.WALLET_MANAGEMENT,
    // tip: 'Tip for Wallet Management',
    enable: false,
    premium: true,
  },
  {
    name: 'Ticket System',
    icon: <MdAssignmentAdd />,
    link: ROUTES.DASHBOARD.TICKET_SYSTEM,
    // tip: 'Tip for Ticket System',
    enable: true,
    premium: false,
  },
  {
    name: 'Giveaway',
    icon: <AiOutlineGift />,
    link: ROUTES.DASHBOARD.GIVEAWAY,
    enable: true,
    premium: false,
  },
  {
    name: 'Role System',
    icon: <RiShieldUserLine />,
    link: ROUTES.DASHBOARD.ROLE_SYSTEM,
    enable: false,
    premium: false,
  },
  {
    name: 'Engagement System',
    icon: <RiShieldUserLine />,
    link: 'engagement',
    enable: false,
    premium: false,
  },
]

export default dataSidebar
