import {
  Button,
  Spinner
} from '@material-tailwind/react'

import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import NftSetup from './_components/NftSetup'
import RoleSetup from './_components/RoleSetup'
import TokenSetup from './_components/TokenSetup'

import iconNFT from '../../img/icon-nft.svg'
import iconToken from '../../img/icon-token.svg'
import { OkDialog } from '../../components/Dialogs/OkDialog'

const CreateHolderVerification = ({ onBackButtonClick }) => {
  const [selectTypeHolder, setSelectTypeHolder] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [isLastStep, setIsLastStep] = useState(false)
  const [isFirstStep, setIsFirstStep] = useState(false)
  const [roleToken, setRoleToken] = useState(null)
  const [roleNft, setRoleNft] = useState(null)
  const [quantityToken, setQuantityToken] = useState(1)
  const [creatorAddress, setCreatorAddress] = useState(null)
  const [tokenAddress, setTokenAddress] = useState(null)
  const [collectionAddress, setCollectionAddress] = useState(null)
  const [collectionName, setCollectionName] = useState(null)
  const [rolePerQty, setRolePerQty] = useState(null)
  const [hashlist, setHashlist] = useState(null)
  const [hashlistFile, setHashlistFile] = useState(null)
  const [skipRole, setSkipRole] = useState(null)
  const [loading, setLoading] = useState(false)
  const [doneSync, setDoneSync] = useState(false)
  const [openDoneSync, setOpenDoneSync] = useState(false)
  const [syncData, setSyncData] = useState(null)
  const [roleAttribute, setRoleAttribute] = useState(null)
  const [isListings, setIsListings] = useState(false)
  const [isSales, setIsSales] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleRoleTokenSelect = (selectedRole) => {
    setRoleToken(selectedRole)
  }
  const handleRoleNftSelect = (selectedRole) => {
    setRoleNft(selectedRole)
  }
  const handleQuantityTokenSelect = (quantity) => {
    setQuantityToken(quantity)
  }
  const handleTokenAddress = (tokenAddress) => {
    setTokenAddress(tokenAddress)
  }
  const handleCreatorAddress = (creatorAddress) => {
    setCreatorAddress(creatorAddress)
  }
  const handleCollectionName = (collectionName) => {
    setCollectionName(collectionName)
  }
  const handleCollectionAddress = (collectionAddress) => {
    setCollectionAddress(collectionAddress)
  }
  const handleHashlist = (hashlist) => {
    setHashlist(hashlist)
  }
  const handleSkipRole = (skipRole) => {
    setSkipRole(skipRole)
  }
  const handleHashlistFile = (hashlistFile) => {
    setHashlistFile(hashlistFile)
  }
  const handleRolesQuantity = (rolesQuantity) => {
    setRolePerQty(rolesQuantity)
  }
  const handleRolesAttribute = (rolesAttribute) => {
    setRoleAttribute(rolesAttribute)
  }
  const handleIsListings = (isListings) => {
    setIsListings(isListings)
  }
  const handleIsSales = (isSales) => {
    setIsSales(isSales)
  }
  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1)
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1)

  const configCollectionDefinition = async () => {
    setLoading(true)
    const errors = []
    if (!collectionName) {
      errors.push('Collection name is required.')
    }
    if (!(creatorAddress || collectionAddress || tokenAddress)) {
      errors.push('A valid address is required.')
    }
    if (!(selectTypeHolder ? roleToken?.value : roleNft?.value)) {
      errors.push('Please select a role.')
    }
    if (errors.length > 0) {
      toast.error(errors.join('\n'), {
        style: { whiteSpace: 'pre-line' },
      })
      setLoading(false)
      return
    }

    const infoDefinition = {
      guildId: localStorage.getItem('guildId'),
      isToken: selectTypeHolder,
      collectionName: collectionName,
      creatorAddress: selectTypeHolder ? '' : creatorAddress,
      collectionMint: selectTypeHolder ? '' : collectionAddress,
      tokenAddress: tokenAddress ? tokenAddress : '',
      hashlist: hashlist ? [hashlistFile] : [],
      holderRole: selectTypeHolder ? roleToken.value : roleNft.value,
      skipRoleManagement: skipRole,
      minimumQuantity: selectTypeHolder ? quantityToken : 1,
    }

    try {
      const response =
        await HttpService.postCollectionDefinition(infoDefinition)
      setSyncData(response.data)
      setLoading(false)
      setOpenDoneSync(true)
    } catch (error) {
      const errorMessage = extractErrorMessage(error, selectTypeHolder)
      console.error('Fetch error:', errorMessage)
      toast.error(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const extractErrorMessage = (error, selectTypeHolder) => {
    const statusCode = error?.response?.status

    switch (statusCode) {
      case 409:
        return 'A collection with this address already exists.'
      case 500:
        return 'You are not authorized to create a collection in this server.'
      case 400:
        return selectTypeHolder
          ? 'Confirm the token address used'
          : 'Confirm the collection address used'
      default:
        return 'Ops... something gone wrong.'
    }
  }

  const configCollectionRoles = () => {
    setLoading(true)
    if (syncData) {
      const infoRoles = {
        guildId: localStorage.getItem('guildId'),
        collectionId: syncData._id,
        // holderVerificationChannelId: holderVerificationChannelId,
        rolePerAttribute: roleAttribute.map((attr) => ({
          roleId: attr.RoleId,
          roleName: attr.RoleName,
          attributeName: attr.AttributeName,
          attributeValue: attr.AttributeValue,
        })),
        rolePerQty: rolePerQty.map((qty) => ({
          roleId: qty.RoleId,
          roleName: qty.RoleName,
          numberOfNfts: qty.NumberOfNfts,
        })),
        listing: isListings,
        sales: isSales,
      }
      HttpService.postCollectionRoles(infoRoles)
        .then((data) => {
          setIsLastStep(true)
          successConfig()
          setLoading(false)
        })
        .catch((error) => {
          console.error('Errr send configurations:', error)
          setLoading(false)
          toast.error(`Opss, something gone wrong: ${error.message}`)
        })
    }
  }

  const nextSync = () => {
    handleNext()
    setDoneSync(true)
  }

  const successConfig = () => {
    setSuccess(true)
  }

  const handleCongratulation = () => {
    window.location.reload()
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }))
  }

  const SelectTypeHolderHandler = (isToken)=>{
    setSelectTypeHolder(isToken)
    handleNext()
  }

  return (
    <>
        {loading && (
          <div className="fixed w-full h-full top-0 left-0 bg-dark/60 z-50 flex items-center justify-center">
            <Spinner className="w-12 h-12 text-secondary-bright" />
          </div>
        )}

      <OkDialog
        title={'Data successfully fetched!'}
        content={`Now you can configure your collection.`}
        isOpen={openDoneSync}
        onConfirmClickEvent={() => {
          nextSync()
          setOpenDoneSync(false)
        }} />

      <OkDialog
        title={'Setup complete!'}
        content={`Your collection and its roles have been successfully configured.`}
        isOpen={success}
        onConfirmClickEvent={handleCongratulation} />


        {/**************************************** Content ****************************************/}

        {/* Set Type Holder: NFT or TOKEN */}

        {activeStep === 0 && (

          <>
            <Button size='sm' className="button-secondary mb-4" onClick={onBackButtonClick}>
              &lt;&nbsp;Back
            </Button> 
            <h1>New Collection</h1>
            <p>Select the wallet type for your collection:</p>

            <div className="grid lg:grid-cols-12 gap-4 mt-8">

              <div className='lg:col-span-5 lg:col-start-2 xl:col-span-4 xl:col-start-3'>
                <button type="button" aria-label="NFT" onClick={() => SelectTypeHolderHandler(false)} className='clickable-card flex flex-row items-center gap-4 !px-8'>

                  <div>
                    <img src={iconNFT} className='h-24 w-24 filter-accent-blue' />
                  </div>
                  <div className='text-left w-[180px]'>
                    <h3 className='mb-2'>NFT</h3>
                    <small>Collectible, unique assets based on blockchain tokens</small>
                  </div>

                </button>

              </div>

              <div className='lg:col-span-5 xl:col-span-4'>
                <button type="button" aria-label="Token" onClick={() => SelectTypeHolderHandler(true)} className='clickable-card flex flex-row items-center gap-4 !px-8'>

                  <div>
                    <img src={iconToken} className='h-24 w-24 filter-accent-green' />
                  </div>
                  <div className='text-left w-[180px]'>
                    <h3 className='mb-2'>Token</h3>
                    <small>Digital asset representation for blockchain transaction</small>
                  </div>

                </button>
              </div>

            </div>
          </>
        )}

        {/* Define attributes */}
        {activeStep === 1 && (
          <>
            {selectTypeHolder ? (
              <TokenSetup
                onRoleSelect={handleRoleTokenSelect}
                onQuantitySelect={handleQuantityTokenSelect}
                onTokenAddress={handleTokenAddress}
                onCollectionName={handleCollectionName}
                onSkipRole={handleSkipRole}
                onBackButtonClick={handlePrev}
                onNextButtonClick={configCollectionDefinition}
              />
            ) : (
              // I'm here, TODO: Implement onNextButtonClick and remove outside Prev/Next
              <NftSetup
                onRoleSelect={handleRoleNftSelect}
                onQuantitySelect={handleQuantityTokenSelect}
                onCreatorAddress={handleCreatorAddress}
                onCollectionName={handleCollectionName}
                onCollectionAddress={handleCollectionAddress}
                onHashlist={handleHashlist}
                onSkipRole={handleSkipRole}
                onHashlistFile={handleHashlistFile}
                onBackButtonClick={handlePrev}
                onNextButtonClick={configCollectionDefinition}
              />
            )}
          </>
        )}

        {/* Define notifications */}
        {activeStep === 2 && (
          <>
            <RoleSetup
              syncData={syncData}
              onRolesQuantity={handleRolesQuantity}
              onRolesAttribute={handleRolesAttribute}
              onListings={handleIsListings}
              onSales={handleIsSales}
              onNextButtonClick={configCollectionRoles}
            />
          </>
        )}

        {/* botão  */}
        {/* <div className="bottom-5 left-5 flex py-20 md:py-5 justify-end gap-x-2">
          {activeStep !== 0 && (
            <Button className="bg-dark" onClick={() => handlePrev()}>
              Prev
            </Button>
          )}
          <Button
            className="bg-main "
            onClick={() => {
              if (activeStep === 0) {
                handleNext()
              } else if (activeStep === 1) {
                configCollectionDefinition()
              } else if (activeStep === 2) {
                configCollectionRoles()
              }
            }}
          >
            Next
          </Button>
        </div> */}

    </>
  )
}

export default CreateHolderVerification
