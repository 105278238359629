import React from 'react'
import CreateStakingSetup from './CreateStakingSetup'

const Staking = () => {
  return (
    <section className="text-white py-2 sm:py-5 relative h-full">
      <div className="flex justify-between items-center container mx-auto pb-10">
        <h1 className="text-4xl font-bold">Staking Setup</h1>
      </div>
      <CreateStakingSetup />
    </section>
  )
}

export default Staking
