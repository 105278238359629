import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function OkDialog({ title, content, size, isOpen, onConfirmClickEvent }) {
  return (
    <>
      <Dialog open={isOpen} handler={onConfirmClickEvent} size={size || "sm"} className="bg-background border border-primary">
        <DialogHeader>
          <h4>{title}</h4>
        </DialogHeader>
        <DialogBody>
          <p>{content}</p>
        </DialogBody>
        <DialogFooter>
          <Button onClick={onConfirmClickEvent} className='button-primary'>
            OK
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}