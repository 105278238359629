import {
  Input,
  Button,
  IconButton,
  Textarea,
  Chip,
} from '@material-tailwind/react'
import EmojiPicker from 'emoji-picker-react'
import React, { useState, useEffect } from 'react'
import { MdOutlineEmojiEmotions } from 'react-icons/md'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { SelectDefault } from '../components/ui/select'

const CreateTicket = () => {
  const guildId = localStorage.getItem('guildId')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [text, setText] = useState('')
  const [list, setList] = useState(['⁉️ Help/Question', '🤝 Collab'])
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [customMessage, setCustomMessage] = useState(
    'If you need any help, please select an option below that best defines your issue to open a ticket with us.'
  )

  const onEmojiClick = (emojiObject) => {
    setText((prevText) => prevText + emojiObject.emoji + ' ')
    setShowEmojiPicker(false)
  }

  const handleAddToList = () => {
    if (text.trim()) {
      setList((prevList) => [...prevList, text])
      setText('')
    }
  }

  const handleRemoveItem = (itemToRemove) => {
    setList((prevList) => prevList.filter((item) => item !== itemToRemove))
  }

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const saveTicketAssistantConfig = async () => {
    setLoading(true)
    const errors = []
    if (!selectedChannel) {
      errors.push('Select a channel')
    }
    if (!selectedRole) {
      errors.push('Select a role')
    }
    if (list.length === 0) {
      errors.push('Add at least one custom option')
    }
    if (customMessage.trim() === '') {
      errors.push('Enter a custom message')
    }
    if (errors.length > 0) {
      toast.error(errors.join('\n'), { style: { whiteSpace: 'pre-line' } })
    } else {
      const data = {
        guildId: localStorage.getItem('guildId'),
        roleId: selectedRole.value,
        channelId: selectedChannel.value,
        reasons: list,
        customMessage: customMessage,
      }
      try {
        const response = await HttpService.postCreateTicketAssistant(data)
        toast.success('Ticket assistant config saved successfully!')
        cancelTicketAssistantConfig()
      } catch (error) {
        toast.error('Opss, something gone wrong.')
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const cancelTicketAssistantConfig = () => {
    setSelectedChannel(null)
    setSelectedRole(null)
    setText('')
    setCustomMessage(
      'If you need any help, have questions of any kind please select an option below that best categorizes your contact to open a ticket with us.'
    )
    setList(['⁉️ Help/Question', '🤝 Collab'])
  }

  return (
    <>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">

        <div>

          <SelectDefault
            onChange={(e) => setSelectedChannel(e)}
            options={dropdownChannels}
            isLoading={isLoading}
            placeholder="Select channel"
            label="Channel"
            value={selectedChannel}
          />
          <small className='field-description'>
            This is the channel where tickets will be opened
          </small>

        </div>

        <div>

          <SelectDefault
            onChange={(e) => setSelectedRole(e)}
            options={dropdownRoles}
            placeholder="Select role"
            label="Role"
            isLoading={isLoading}
            value={selectedRole}
          />
          <small className='field-description'>
            This will be the ticket support's helper role
          </small>          

        </div>

        <div className='lg:col-span-2 xl:col-span-3'>
          <Textarea color="brown" label="Support message" onChange={(e) => setCustomMessage(e.target.value)} value={customMessage} placeholder="Enter ticket support message" className='custom-textarea' />
        </div>

      </div>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-2 mt-6">

        <div className="relative">

          <Input
            label="Custom option"
            placeholder='Enter custom option'
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddToList()
                e.preventDefault()
              }
            }}
          />
          <div className="absolute right-2 top-2 flex gap-1">
            <IconButton size="sm" className="rounded" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
              </svg>
            </IconButton>
          </div>

          {showEmojiPicker && (
            <div className="absolute top-full mt-0 right-0 z-30">
              <EmojiPicker
                theme="dark"
                searchDisabled
                skinTonesDisabled
                height={"360px"}
                width={"300px"}
                lazyLoadEmojis={true}
                recentEmojis={true}
                reactionsDefaultOpen={false}
                onEmojiClick={onEmojiClick}
              />
            </div>
          )}

          {/*
              <input
                placeholder="Enter custom options"
                className="w-full  text-white  first-of-type:pl-4 outline-none bg-second border-none "
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddToList()
                    e.preventDefault()
                  }
                }}
              />
              
             <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className="p-2 relative">
                <MdOutlineEmojiEmotions size={20} />
              </button>

          */}
        </div>

        <div>
          <Button onClick={handleAddToList} size='md' className='button-primary'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            Add
          </Button>

          {/* <Button
            variant="filled"
            color="deep-purple"
            className="bg-accentHouver"
            onClick={handleAddToList}
          >
            ADD
          </Button> */}

        </div>

      </div>

      <div className='flex flex-grow flex-col mt-2 unclickable-card'>

        <small>List of custom options</small>

        {list && list.length > 0 && (
          <div className="flex flex-wrap mt-4 gap-2">
            {list.map((item, index) => (
              <Chip key={index} value={item} onClose={() => handleRemoveItem(item)} className='badge-accent-sun' />
            ))}
          </div>
        )}

      </div>

      <div className='grid grid cols-1 lg:grid-cols-12 mt-10 pb-10'>
        <Button onClick={saveTicketAssistantConfig} className="button-primary lg:col-span-4" >
          Save
        </Button>
      </div>

    </>
  )
}

export default CreateTicket
