import React, { useState } from 'react'
import Switch from '../components/ui/switch'
import SetupGiveaway from './SetupGiveaway'

const Giveaway = () => {
  const [enableGiveaway, setEnableGiveaway] = useState(false)
  return (
    <section className='container'>
      
      <h1>Giveaway</h1>

      <SetupGiveaway />

    </section>
  )
}

export default Giveaway
