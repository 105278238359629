import React from 'react'
import iconCody from '../../img/icon-cody.svg'

const Faq = () => {
  return (
    <section className='container text-center' id="faq">

      <h1>FAQ</h1>
  
      <div className='grid md:grid-cols-2 lg:grid-cols-12 gap-4'>

        <div className='text-left unclickable-card lg:col-span-4 lg:col-start-3'>
          <img src={iconCody} className='filter-secondary-bright max-w-[32px] h-auto' />
          <h2 className='my-4'>What is TekTools?</h2>
          <p className='mb-2'>TekTools is a Discord-based Web3 project companion that improves the security of NFT projects while offering improved management utilities to their members.</p>
        </div>

        <div className='text-left unclickable-card lg:col-span-4'>
          <img src={iconCody} className='filter-secondary-bright max-w-[32px] h-auto' />
          <h2 className='my-4'>Why TekTools?</h2>
          <p className='mb-2'>TekTools gives NFT project owners peace of mind, by offering them a wide range of capabilities to manage projects and keep their members happy and secured — thus, owners can focus on their unique ideas and innovations leaving the hard work to us.</p>
        </div>

        <div className='text-left unclickable-card lg:col-span-4 lg:col-start-3'>
          <img src={iconCody} className='filter-secondary-bright max-w-[32px] h-auto' />
          <h2 className='my-4'>How can TekTools make a project safer?</h2>
          <p className='mb-2'>TekTools significantly reduces the amount of bots needed in your server. Although this alone eliminates most weak points and potential breaches, we're constantly adding new security features keeping an eye on roles and members' behavior.</p>
        </div>    

        <div className='text-left unclickable-card lg:col-span-4'>
          <img src={iconCody} className='filter-secondary-bright max-w-[32px] h-auto' />
          <h2 className='my-4'>Does TekTools have an NFT collection?</h2>
          <p className='mb-2'>Yes! Join our Discord server for more information: <a href="https://discord.gg/tekros">https://discord.gg/tekros</a></p>
        </div> 

        <div className='text-left unclickable-card md:col-span-2 lg:col-span-8 lg:col-start-3'>
          <img src={iconCody} className='filter-secondary-bright max-w-[32px] h-auto' />
          <h2 className='my-4'>Does TekTools offer support?</h2>
          <p className='mb-2'>Yes! For any support questions, please join our Discord server <a href="https://discord.gg/tekros">(https://discord.gg/tekros)</a>. Please notice that only Premium Plan members have a dedicated support channel in their server.</p>
        </div>                       

      </div>

    </section>
  )
}

export default Faq
