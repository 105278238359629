import React from 'react'
import DecentralizedGallery from './_components/DecentralizedGallery'
import DecentralizedUpload from './_components/DecentralizedUpload'
//TODO : Aplicar logica após corrigir erro 500

const DecentralizedStorage = () => {
  return (
    <>
      <div className="flex flex-col container mx-auto w-full h-full py-5 text-white gap-y-10">
        <h1 className="text-4xl font-bold">Decentralized Storage</h1>
        <span className=''>
          Easily upload files to Shadow Drive, 1GB of storage included
        </span>
        <DecentralizedUpload />
        <hr className='border-t border-basic' />
        <DecentralizedGallery />
      </div>
    </>
  )
}

export default DecentralizedStorage
