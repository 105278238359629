import { Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { RolePerAttibute } from './RolePerAttibute'
import { RolePerQty } from './RolePerQty'
import { SeparatorDefault } from '../../components/ui/separator'
import { Button } from '@material-tailwind/react'

const RoleSetup = ({
  syncData,
  onRolesQuantity,
  onRolesAttribute,
  onSales,
  onListings,
  onNextButtonClick,
}) => {
  const [isSales, setIsSales] = useState(false)
  const [isListings, setIsListings] = useState(false)
  const [rolePerQty, setRolePerQty] = useState([])
  const [rolePerAttribute, setRolePerAttribute] = useState([])
  const handleRolesPerQtyUpdate = (updatedRolesPerQty) => {
    setRolePerQty(updatedRolesPerQty)
  }

  const handleRolesPerAttributeUpdate = (updatedRolesPerAttribute) => {
    setRolePerAttribute(updatedRolesPerAttribute)
  }

  useEffect(() => {
    onRolesQuantity(rolePerQty), onRolesAttribute(rolePerAttribute)
  }, [rolePerQty, rolePerAttribute])

  return (
    <>
      <h1>Roles and definitions</h1>

      {/* ------------------   Switchs    -----------------  */}
      {syncData?.IsToken === false && (
        <>
          <h3>Listing and sales</h3>

          <div>
            <Switch
              label="Enable sales"
              checked={isSales}
              onChange={() => {
                const newSalesState = !isSales
                setIsSales(newSalesState)
                onSales(newSalesState)
              }}
            />
          </div>
          <div className='pt-4'>
            <Switch
              label="Enable listings"
              checked={isListings}
              onChange={() => {
                const newListingsState = !isListings
                setIsListings(newListingsState)
                onListings(newListingsState)
              }}
            />
          </div>

          <SeparatorDefault />

        </>
      )}

      {/* ------------------   Inputs de Roles    -----------------  */}
      <h3>Roles by quantity</h3>

      <RolePerQty listRoleQuantity={handleRolesPerQtyUpdate} />

      {syncData?.IsToken === false && (
        <>
          <SeparatorDefault />
          <h3>Roles by type and value</h3>
          <RolePerAttibute
            creatorAddress={syncData?.VerifiedAddress}
            collectionMint={syncData?.CollectionMint}
            listRoleAttribute={handleRolesPerAttributeUpdate}
          />
        </>
      )}


      <div className='grid grid cols-1 lg:grid-cols-12 mt-10 pb-10'>
        <Button className="button-primary lg:col-span-4" onClick={onNextButtonClick} >
          Done!
        </Button>
      </div>

    </>
  )
}

export default RoleSetup
