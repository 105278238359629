import React from 'react'
import { useState } from 'react'
import ProfileMenu from "../components/Main/ProfileMenu";
import iconDiscord from '../img/icon-discord.svg'
import {
    Navbar,
    Button,
    IconButton,
    Chip,
} from '@material-tailwind/react'

import logo from '../img/tektools-logotype.svg'


const AdminHeader = ({ isMobile, isDrawerOpen, onOpenDrawerClick }) => {
    const [userData, setUserData] = useState(
        JSON.parse(localStorage.getItem('userData'))
    )
    const handleClick = () => {
        const authUrl = process.env.REACT_APP_AUTH_DISCORD
        window.location.replace(authUrl)
    }

    return (
        <header className="sticky top-0 z-10 mb-4">

            <Navbar className="border-b border-primary bg-background">

                <div className="container flex flex-row items-center px-0 md:justify-end">

                    {isMobile && (
                        <>
                            <div className='flex flex-row items-center grow'>

                                <IconButton
                                    className="h-8 w-8 bg-transparent hover:bg-transparent text-grayscale-bright hover:text-white"
                                    onClick={onOpenDrawerClick}
                                >
                                    {isDrawerOpen ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10">
                                            <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10">
                                            <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
                                        </svg>
                                    )}
                                </IconButton>

                                {/* Top navbar logo + BETA chip marker */}
                                <div className='flex flex-row items-center ml-2'>
                                    <a href="/dashboard">
                                        <span className="sr-only">TekTools</span>
                                        <img id="tektools-logo" src={logo} alt="TekTools" />
                                    </a>
                                    <div>
                                        <Chip
                                            value='Beta'
                                            className='badge-accent-blue'
                                        />
                                    </div>
                                </div>

                            </div>
                        </>)}

                    {
                        userData
                            ? (<ProfileMenu userData={userData} />)
                            : (

                                <Button onClick={handleClick} size="sm" className="button-primary w-auto my-[2px]">
                                    <img src={iconDiscord} />
                                    Login
                                </Button>
                            )
                    }
                </div>
            </Navbar>
        </header>
    )
}

export default AdminHeader;