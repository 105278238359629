import { Button } from '@material-tailwind/react'
import { useRef, useState } from 'react'
import { FaCloudUploadAlt, FaTimes } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { HttpService } from '../../../utils/axios-httpService'
import { InputDefault } from '../../components/ui/input'

export default function DecentralizedUpload() {
  const guildId = localStorage.getItem('guildId')
  const [uploadedImage, setUploadedImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [dragActive, setDragActive] = useState(false)
  const inputRef = useRef(null)

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
      if (!allowedTypes.includes(file.type)) {
        toast.error('Please select a valid image file (JPG, JPEG, PNG, GIF).')
        setUploadedImage(null)
        return
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        setUploadedImage(e.target.result)
        setFile(file)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleImageUpload({ target: { files: e.dataTransfer.files } })
    }
  }

  const handleCancelUpload = () => {
    setUploadedImage(null)
    setFile(null)
  }

  const handleSaveItem = async () => {
    if (!fileName || !file) {
      toast.error('Please fill in all fields before saving the item.')
      return
    }

    const formData = new FormData()
    formData.append('guildId', guildId)
    formData.append('fileName', fileName)
    formData.append('file', file)
    try {
      setIsLoading(true)
      const response = await HttpService.postCreateStorage(formData)
      toast.success('Uploaded successfully!')
      setTimeout(() => {
        window.location.reload()
      }, 700)


      setFileName('')
      setFile(null)
      setUploadedImage(null)
    } catch (error) {
      console.error('Error uploading item:', error)
      toast.error(error.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="space-y-4">
      <div
        className={`p-6 border-2 border-dashed rounded-lg cursor-pointer ${dragActive ? 'border-accentMain' : 'border-main'
          } transition-colors duration-300 ease-in-out`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center space-y-4">
          {uploadedImage ? (
            <div className="relative flex flex-col md:flex-row w-full items-center justify-center gap-5">
              <img
                src={uploadedImage}
                alt="Preview"
                className="w-48 h-48 object-cover rounded-lg"
              />

              <div className="flex flex-col gap-2 w-full md:w-1/2">
                <div className="flex-grow">
                  <InputDefault
                    label="File Name"
                    placeholder="Enter the item name"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                </div>
                <div className='flex gap-2 md:self-end justify-between'>
                  <Button variant='text' color='red' onClick={handleCancelUpload}>
                    Cancel
                  </Button>
                  <Button
                    color="deep-purple"
                    onClick={handleSaveItem}
                    disabled={!uploadedImage || !fileName || isLoading}
                  >
                    Upload File
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <FaCloudUploadAlt className="w-12 h-12 text-gray-400" />
          )}
          {!uploadedImage && (
            <label htmlFor="imageUploadInput" className="cursor-pointer text-center">
              <span className="text-primary font-semibold hover:underline">
                Click to upload
              </span>
              <span className="text-gray-500"> or drag and drop</span>
              <input
                id="imageUploadInput"
                type="file"
                accept=".png, .jpeg, .jpg, .gif"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
                ref={inputRef}
              />
            </label>
          )}
        </div>
      </div>

    </div>
  )
}
