import {
    Drawer,
    IconButton,
    List,
    ListItem
} from '@material-tailwind/react'

import logo from '../../img/tektools-logotype.svg'

export const DrawerMenu = ({isOpen, onCloseClick}) => {

    return (
        <Drawer open={isOpen} onClose={onCloseClick} className='border-r border-primary'>

            <div className="mb-2 flex items-center justify-between p-4">

                {/* Top logo */}
                <span className="sr-only">TekTools</span>
                <img id="tektools-logo" src={logo} alt="TekTools" />

                 {/* Close drawer button */}
                <IconButton 
                onClick={onCloseClick}
                className='!bg-transparent !text-grayscale-bright hover:!text-white'>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-5 w-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </IconButton>

            </div>
            <List className='uppercase'>
                <ListItem>
                    <a href="/#features">
                        Features
                    </a>
                </ListItem>
                <ListItem>
                    <a href="/#pricing">
                        Pricing
                    </a>    
                </ListItem>
                <ListItem className='border-none'>
                    <a href="/#faq">
                        FAQ
                    </a>
                </ListItem>
            </List>
        </Drawer>
    )
}