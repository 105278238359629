import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Input,
  DialogHeader
} from "@material-tailwind/react";
import React, { useState, useEffect } from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import CreateHolderVerification from './CreateHolderVerification'
import LoadHolderVerification from './LoadHolderVerification'
import { SelectDefault } from '../../admin/components/ui/select'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { fetchChannels } from '../../utils/channelUtils'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'

const SendInstructions = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);

  const guildId = localStorage.getItem('guildId')
  const myServer = JSON.parse(localStorage.getItem('myServers'));
  //TODO: improve this logic
  const selectedGuildName = Array.isArray(myServer) ? myServer.find(c => c.id === guildId)?.name : null;
  const [isLoading, setIsLoading] = useState(false)

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  const handleChannelChange = (selectedOption) => {
    setSelectedChannel(selectedOption)
  }

  const sendInstructionsClickHandler = () => {
    if (!selectedChannel) {
      toast.error('Please select a channel.')
      return
    }
    HttpService.postSendMessageHolderVerification(guildId, selectedChannel.value)
      .then((response) => {
        handleOpen()
        setSelectedChannel(null)
        toast.success('Instructions sent successfully!')
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
      })
  }

  {/* Send instructions modal */ }
  return (
    <>
      <Button fullWidth size='md' onClick={handleOpen} className="button-secondary">
        Send Instructions
      </Button>

      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem] bg-background border border-primary">
          <CardBody className="flex flex-col gap-4">

            <div className="flex flex-row justify-between">

              <h4>Send instructions</h4>

              {/* Modal with close button sample: https://www.material-tailwind.com/docs/react/dialog#web-3-dialog */}
              <IconButton
                size="sm"
                className='!bg-transparent hover:text-interactive-bright'
                onClick={handleOpen}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>

            </div>

            <div>
              <small>
                Current server
              </small>
              <p className="mb-3 emphasis truncate">
                {selectedGuildName}
              </p>
            </div>
            <div>
              <SelectDefault
                label="Channel"
                placeholder="Select channel"
                isLoading={isLoading}
                options={dropdownChannels}
                value={selectedChannel}
                onChange={handleChannelChange}
              />
              <small className="field-description">Press the button below to send TekTools instructions to the selected channel.</small>
            </div>
          </CardBody>
          <CardFooter className="pt-0">
            <Button className="button-primary" onClick={sendInstructionsClickHandler} fullWidth>
              Send
            </Button>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
}

const HolderVerification = () => {
  const [openNewCollection, setOpenNewCollection] = useState(false)
  const [search, setSearch] = useState('')
  //We need to chanage the behaviour of this button. We want to not show the new,search and send instructions when we have a new collection.
  const handleOpenNewCollection = () => setOpenNewCollection((cur) => !cur)

  return (

    <section className='container'>

      {openNewCollection ? (
        <CreateHolderVerification onBackButtonClick={handleOpenNewCollection} />
      ) : (
        <>
          <h1>Holder Verification</h1>

          <div className='grid lg:grid-cols-4 xl:grid-cols-5 gap-4 items-center mb-4 lg:mb-8'>

            <div className='row-start-3 lg:row-start-1 col-span-1 lg:col-span-2 mt-6 lg:mt-0'>
              <Input icon={<MagnifyingGlassIcon />} label="Search collection" placeholder="Type a name or address" value={search} onChange={(e) => { setSearch(e.target.value) }} />
            </div>

            {/* This button is placed in LoadHolderVerification */}
            <Button fullWidth onClick={handleOpenNewCollection} size='md' className="button-primary lg:col-start-3 xl:col-start-4">
              New Collection
            </Button>

            {/* This button is placed in LoadHolderVerification */}
            <SendInstructions />
          </div>
          <LoadHolderVerification search={search} />
        </>
      )}

    </section>

  )
}

export default HolderVerification
