import React, { useEffect, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { fetchStorage } from '../../../utils/storageUtils'
import { HttpService } from '../../../utils/axios-httpService'
import { toast } from 'react-toastify'
import { Loading } from '../../components/ui/loading'
import { Spinner } from '@material-tailwind/react'


const DecentralizedGallery = () => {
  const guildId = localStorage.getItem('guildId')
  const [imageGallery, setImageGallery] = useState([])
  const [noImages, setNoImages] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const loadImages = () => {
    setIsLoading(true)
    fetchStorage(guildId).then((data) => {
      if (data.data.keys.length > 0) {
        setImageGallery(data.data.keys)
      } else {
        setNoImages(true)
      }
      setIsLoading(false)
    })

  }


  useEffect(() => {
    if (!imageGallery || imageGallery.length === 0) {
      loadImages()
    }
  }, [])


  const handleDeleteImage = (index) => {
    HttpService.deleteStorage(guildId, imageGallery[index])
      .then(() => {
        toast.success('Image deleted successfully')
        loadImages()
      })
      .catch((error) => {
        toast.error('Error deleting image')
      })
  }



  if (isLoading) {
    return <div className="flex justify-center items-center h-full">
      <Spinner className="w-12 h-12 text-main/50" />
    </div>
  }

  if (noImages) {
    return <div className="flex justify-center  h-full">
      <p className="uppercase text-sm font-medium">No images found</p>
    </div>
  }

  return (
    <div className="space-y-8">


      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {imageGallery
          .filter((imageUrl) => !imageUrl.endsWith('.json'))
          .reverse()
          .map((imageUrl, index) => {
            const correctedUrl = imageUrl.replace(
              'https://shdw-drive.genesysgo.net//',
              'https://shdw-drive.genesysgo.net/'
            )
            const fileNameWithExtension = correctedUrl.split('/').pop()
            const fileName = fileNameWithExtension.replace(/^\d+_/, '').split('.')[0]

            return (
              <div key={correctedUrl} className="relative group">

                <div className="w-full h-48 flex items-center justify-center rounded-lg overflow-hidden">
                  <img
                    src={correctedUrl}
                    alt={`Imagem ${index + 1}`}
                    className="max-w-full max-h-full object-contain p-2 rounded-lg"
                  />
                </div>
                <div className="absolute inset-0 bg-dark bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-end justify-center rounded-lg p-2">
                  <p className="text-center px-2 py-1 font-bold bg-dark rounded-lg w-full truncate">
                    {fileName}
                  </p>
                  <button

                    className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
                    onClick={() => handleDeleteImage(index)}
                  >
                    <BiTrash size={20} />
                  </button>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default DecentralizedGallery
