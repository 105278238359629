//Documentation: https://react-select.com/home

import Select from 'react-select'
import makeAnimated from 'react-select/animated'

export const SelectDefault = ({
  options,
  onChange,
  isDisabled,
  placeholder,
  label,
  ref,
  isMulti,
  isLoading,
  value,
}) => {
  const animatedComponents = makeAnimated()

  return (
    <>
      <div className="w-full flex flex-col mt-[-7px]">

        <div className="custom-input-label flex flex-row">
          <div>
            &nbsp;
          </div>
          <div className='custom-input-label-text'>
            {label}
            <div className='label-border'></div>
          </div>
          <div>
            &nbsp;
          </div>
        </div>

        <Select
          isClearable
          value={value}
          options={options}
          components={animatedComponents}
          onChange={onChange}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isLoading={isLoading}
          placeholder={placeholder}
          className=""
          classNamePrefix="select-custom scrollbar-thin scrollbar-thin scrollbar-track-grayscale-deep scrollbar-thumb-grayscale-lucky"
          styles={{
            menu: (provided, state) => ({ /* Drop menu config */
              ...provided,
              backgroundColor: '#333', 
              color: '#fff',
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              scrollbarColor: '',
              marginTop: '1px',
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              padding: state.hasValue ? '0.425rem' : '0.425rem', /* input padding when has text from some option selected */
              height: '46px', /* input height */
              maxHeight: '100%',
              color: '#fff',
              alignItems: 'center',
            }),
            container: (provided, state) => ({
              ...provided,
              color: '#fff',
              margin: 0,
              padding: 0,
              border: 0,
              width: '100%',
            }),
            singleValue: (provided) => ({
              ...provided,
              color: '#fff', /* Text in input after choosing some option */
            }),
            control: (provided, state) => ({
              ...provided,
              height: 'auto',
              maxHeight: '100%',
              maxWidth: '100%', /* Input width */
              backgroundColor: state.isFocused ? 'rgba(83, 72, 69, 0.15)' : 'rgba(32, 25, 19, 0.70)', /* Input focus BG colors, focus and standard */
              color: '#fff',
              borderRadius: 4,
              borderColor:
                state.isFocused || state.isHovered ? '#7d584a' : '#534845', /* Border colors, focused+hovered and standard */
              boxShadow:
                state.isFocused || state.isHovered
                  ? 'none'
                  : 'none',
              paddingLeft: '0.1rem', /* Text and placeholder left padding */
              transition: 'all 0.2s ease',
              '&:hover': { /* Input hover values */
                borderColor: '',
                background: 'rgba(83, 72, 69, 0.15)',
                boxShadow: 'none',
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected /* List (options) item background color */
                ? '#333'
                : state.isFocused
                  ? '#fff' /* Focused item bg color*/
                  : '#333', /* Item bg color */
              color: state.isFocused
                ? '#000' /* Focused item text color */
                : '#fff', /* Item text color */
              cursor: 'pointer',
              maxWidth: '95%', /* Max item's width */
              padding: '0.5rem 0.5rem', /* Item padding, X and Y */
              margin: '2px 0', /* Item margin, X and Y */
              marginLeft: 'auto', /* Item side margin */
              marginRight: 'auto', /* Item side margin */
              borderRadius: '4px',
              '&:hover': { /* Item hover properties */
                backgroundColor: '#fff',
                color: '#000',
                paddingLeft: '1rem',
                transition: 'all 0.3s ease',
              },
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: '#534845',
              color: '#fff',
              borderRadius: 4,
              padding: '0 0.1rem',
              margin: '0 0.1rem',
              justifyContent: 'space-between',
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              color: '#fff',
            }),
          }}
          ref={ref}
        />

      </div>
    </>
  )
}
