import React from 'react'

import {
  Button,
} from '@material-tailwind/react'

import badgeCody from '../../img/badge-cody.svg'
import { SeparatorCody } from '../../admin/components/ui/separatorCody'

const Prices = () => {
  return (
    <section className='container text-center' id="pricing">

      <h1>Pricing</h1>

      <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 pb-4'>

        <div className='unclickable-card'>

          <h2 className='mb-4'>Basic plan</h2>

          <img src={badgeCody} className='filter-grayscale-bright max-w-[100px] h-auto my-4 mx-auto' />

          <p className='emphasis text-5xl text-accent-green'>$0</p>
          <p className='text-accent-green'>Oh yeah, it's free!</p>

          <hr className='!border-grayscale-bright !my-8'/>

          <div className='text-left my-4'>
            <ul className='features-list'>
              <li>Anti-bot Member Verification</li>
              <li>Crowd Sourcing Security System</li>
              <li>Engagement System</li>
              <li>Giveaways</li>
              <li>X/Twitter Raid to Earn</li>
              <li>Whitelist Wallets Collection</li>
              <li>Server Stats</li>
              <li>Ticket System</li>
              <li>Mini Games</li>
              <li>Anonymous Poll System</li>
              <li>Basic support</li>                                                                                   
            </ul>
          </div>

          <Button fullWidth size='md' className='button-primary mx-auto lg:mx-0'>
            Select plan
          </Button>          

        </div>     

        <div className='unclickable-card'>

          <h2 className='mb-4'>Lite plan</h2>

          <img src={badgeCody} className='filter-accent-blue max-w-[100px] h-auto my-4 mx-auto' />

          <p className='emphasis text-5xl text-accent-green'>$45</p>
          <p className='text-accent-green'>per month, billed annualy</p>

          <p className='my-2'>or <span className='emphasis text-2xl px-1'>$50</span>/month, billed each 6 months</p>

          <p>or <span className='emphasis text-2xl px-1'>$50</span> for a single month</p>

          <hr className='!border-accent-blue !my-8'/>

          <div className='text-left mb-4'>
            <p>Everything in Basic Plan, plus:</p>
            <ul className='features-list'>
              <li>Holder Verification</li>
              <li>Roles Per Quantity (limited)</li>
              <li>Roles Per Trait (limited)</li>
              <li>Sales/listings Notifications</li>
              <li>X/Twitter Raid to Earn w/ Tokens</li>
              <li>Discord Backups</li>
              <li>Advanced support</li>
            </ul>
          </div>

          <Button fullWidth size='md' className='button-primary mx-auto lg:mx-0 mt-8'>
            Select plan
          </Button>          

        </div>     

        <div className='unclickable-card md:col-span-2 lg:col-span-1'>

          <h2 className='mb-4'>Premium plan</h2>

          <img src={badgeCody} className='filter-accent-sun max-w-[100px] h-auto my-4 mx-auto' />

          <p className='emphasis text-5xl text-accent-green'>$70</p>
          <p className='text-accent-green'>per month, billed annualy</p>

          <p className='my-2'>or <span className='emphasis text-2xl px-1'>$85</span>/month, billed each 6 months</p>

          <p>or <span className='emphasis text-2xl px-1'>$100</span> for a single month</p>

          <hr className='!border-accent-sun !my-8'/>

          <div className='text-left mb-4'>
            <p>Everything in Lite Plan, plus:</p>
            <ul className='features-list'>
              <li>Holders per Quantity (extended)</li>
              <li>Roles per Trait (extended)</li>
              <li>Staking System</li>
              <li>Airdrop System</li>
              <li>Revenue Sharing System</li>
              <li>Holder Snapshots</li>
              <li>Advanced support</li>
            </ul>
          </div>

          <Button fullWidth size='md' className='button-primary mx-auto lg:mx-0 mt-8'>
            Select plan
          </Button>          

        </div>     

      </div>

       <SeparatorCody/>

    </section>
  )
}

export default Prices
