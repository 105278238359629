import React from 'react'

import {
  Button,
} from '@material-tailwind/react'

import featuresHolder from '../../img/home-features-holder-verification.png'
import featuresRoles from '../../img/home-features-roles.png'
import featuresUser from '../../img/home-features-user-verification.png'
import featuresStaking from '../../img/home-features-staking.png'
import featuresRevenue from '../../img/home-features-revenue.png'
import featuresDecentralized from '../../img/home-features-decentralized-storage.png'
import featuresCody from '../../img/home-features-cody.png'
import { SeparatorCody } from '../../admin/components/ui/separatorCody'


const Feature = () => {
  return (
    <section className='container text-center' id='features'>

      <h1>Features</h1>

      <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 pb-4'>

        <div className='text-center unclickable-card'>
          <img src={featuresHolder} alt='Holder role and verification' className='max-w-[180px] h-auto mx-auto' />
          <h2 className='mb-4'>Holder role and verification</h2>
          <p className='mb-2'>Effortlessly incorporate role verification into your Discord server within seconds, without complex setups or granting admin permissions.</p>
        </div>

        <div className='text-center unclickable-card'>
          <img src={featuresUser} alt='User verification' className='max-w-[180px] h-auto mx-auto' />
          <h2 className='mb-4'>User verification</h2>
          <p className='mb-2'>Roles granted and removed swiftly, within seconds after user verification. Say goodbye to that 12-hour long wait for role updates after a transfer.</p>
        </div>

        <div className='text-center unclickable-card'>
          <img src={featuresRoles} alt='Roles for quantity and attributes' className='max-w-[180px] h-auto mx-auto' />
          <h2 className='mb-4'>Roles for quantity and attributes</h2>
          <p className='mb-2'>Why limit yourself to basic holder channels? Reward your community by assigning user roles according to their NFT’s attributes and quantities.</p>
        </div>

        <div className='text-center unclickable-card'>
          <img src={featuresStaking} alt='Staking system' className='max-w-[180px] h-auto mx-auto' />
          <h2 className='mb-4'>Staking system</h2>
          <p className='mb-2'>No more complex staking processes. With our robust non-custodial staking system, you can easily reward your holders for keeping their NFTs in their wallets.</p>
        </div>

        <div className='text-center unclickable-card'>
          <img src={featuresRevenue} alt='Revenue sharing' className='max-w-[180px] h-auto mx-auto' />
          <h2 className='mb-4'>Revenue sharing</h2>
          <p className='mb-2'>If it's your case, you can smoothly set up a revenue sharing in your server, allowing members to claim their share in the simplest possible way.</p>
        </div>

        <div className='text-center unclickable-card'>
          <img src={featuresDecentralized} alt='Decentralized storage' className='max-w-[180px] h-auto mx-auto' />
          <h2 className='mb-4'>Decentralized storage</h2>
          <p className='mb-2'>Decentralized Storage
            Upload anything to Solana's Decentralized Storage (until you reach the 1 GB limit).</p>
        </div>

      </div>

      <div className='grid grid-cols-1 lg:grid-cols-12 gap-4 content-center items-center'>

        <div className='lg:col-span-3 lg:col-start-3'>
          <img src={featuresCody} alt='There are still more features' className='w-[284px] mx-auto' />
        </div>

        <div className='lg:text-start lg:col-span-5'>

          <h2 className='pb-4'>"But wait! There's more!"</h2>

          <p className='pb-4'>TekTools offers plenty of other features, and still more are in our roadmap.</p>


          <Button onClick={() => window.open("https://docs.tektools.app/overview/our-features#complete-list-of-features", "X-Twitter")} size='md' className='button-primary mx-auto lg:mx-0 w-full md:w-auto'>
            See all features
          </Button>
          
        </div>

      </div>

       <SeparatorCody/>

    </section>
  )
}

export default Feature
