import { toast } from 'react-toastify'
import {
  IconButton,
  Button,
  Input,
  Card,
  Chip,
} from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { HttpService } from '../../../utils/axios-httpService'
import Social from './Social'
import avatarDiscordStandard from '../../../img/avatar-discord-standard.jpg'
import iconCody from '../../../img/icon-cody.svg'
import iconSolana from '../../../img/icon-solana.svg'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { ConfirmOrCancelDialog } from '../../../components/Dialogs/ConfirmOrCancelDialog'

const SetupAccount = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [wallets, setWallets] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const getSocial = () => {
    HttpService.getMe().then((response) => {
      setWallets(response.data.wallets)
    })
  }

  useEffect(() => {
    getSocial()
  }, [])

  const maskWallet = (wallet) => {
    if (!wallet || wallet.length <= 10) return wallet;
    return `${wallet.slice(0, 7)}...${wallet.slice(-3)}`;
  };

  const changeMainWallet = (wallet) => {
    const maskedWalletAddress = maskWallet(wallet.wallet);
    if (wallet.isMain) {
      toast.info(`Wallet ${maskedWalletAddress} is already the main wallet.`)
      return
    }
    HttpService.patchUpdateMainWallet(wallet.wallet)
      .then(() => {
        toast.success(`Wallet ${maskedWalletAddress} is now the main wallet.`)
        getSocial()
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
      })
  }

  const [walletToDelete, setWalletToDelete] = useState(null)
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false)

  const deleteWalletHandler = async () => {
    HttpService.deleteAccountWalletById(walletToDelete)
      .then(() => {
        toast.success('Wallet deleted successfully!')
        getSocial()
      })
      .catch((error) => {
        toast.error(`Opss, wallet not deleted`)
      }).finally(() => {
        setOpenConfirmDeleteDialog(false)
      })
  }

  const handleConfirmDelete = (wallet) => {
    setWalletToDelete(wallet)
    setOpenConfirmDeleteDialog(true)
  }

  const handleCloseDialog = () => {
    setWalletToDelete(null)
    setOpenConfirmDeleteDialog(false)
  }

  const checkServersHandler = () => {
    window.location.href = '/select-server';
  }

  return (
    <section className='container'>

      <h1 className="mt-8">Account</h1>

      <div className='flex flex-col gap-4 items-center lg:flex-row'>

        {userData?.user.avatar ? (
          <div className='shrink-0'>
            <img className="h-[120px] w-[120px] lg:h-[180px] lg:w-[180px] rounded-full" src={`https://cdn.discordapp.com/avatars/${userData.user.id}/${userData.user.avatar}`} alt="Avatar" />
          </div>
        ) : (
          <div className='shrink-0'>
            <img className="h-[120px] w-[120px] lg:h-[180px] lg:w-[180px] rounded-full" src={avatarDiscordStandard} alt="Avatar tektools" />
          </div>
        )}

        <div className='grow w-full'>
          <h3 className='text-center lg:text-left'>{userData.user.global_name}</h3>
          <Social />
        </div>

      </div>

      <div className='flex flex-row items-center h-28'>
        <div className='grow mr-2 border-b border-secondary'></div>
        <img src={iconCody} className='filter-secondary h-4 w-4' />
        <div className='grow ml-2 border-b border-secondary'></div>
      </div>

      <h3>My managed servers</h3>

      <p>Click the button below to check all Discord servers you manage, and see which ones have TekTools running on them.</p>

      <div className="mt-6">
        <Button size='md' className='w-full md:w-auto button-primary' onClick={checkServersHandler}>
          Check servers
        </Button>
      </div>

      <div className='flex flex-row items-center h-28'>
        <div className='grow mr-2 border-b border-secondary'></div>
        <img src={iconCody} className='filter-secondary h-4 w-4' />
        <div className='grow ml-2 border-b border-secondary'></div>
      </div>


      <div className="w-full flex flex-col md:flex-row md:justify-between items-center">

        <div className="w-full md:w-auto">
          <h3 className='md:mb-0'>My wallets</h3>
        </div>

        {/* 
        TODO:Temporarily disabling the Add new Wallet feature.
        <div className="w-full md:w-auto">
          <Button size='sm' className='button-primary'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            Add New Wallet
          </Button>
        </div> 
        */}

      </div>

      {/* Filter */}
      <div className='my-4'>
        <Input icon={<MagnifyingGlassIcon />} label="Filter by wallet address" placeholder="Enter an address" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value.toLowerCase())} />
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-20">
        {wallets
          ?.sort((a, b) => b.isMain - a.isMain)
          ?.filter((item) =>
            item.wallet.toLowerCase().includes(searchQuery)
          )
          ?.map((item, index) =>
          (<Card key={index} className="unclickable-card flex flex-col justify-between">

            <div className='flex flex-row justify-start items-center gap-4 text-left'>
              <div>
                <img src={iconSolana} className='h-12 w-12' />
              </div>
              <div>
                <small>
                  <span className='card-wallet-solana-text'>Solana</span> | Wallet address:
                </small>
                <p className="truncate emphasis text-2xl">
                  {maskWallet(item.wallet)}
                </p>
              </div>
            </div>

            <div>
              <hr />
              <div className='flex flex-row gap-4'>
                <div className='grow'>
                  {
                    (item.isMain
                      ? (<Chip value="Primary wallet" className='badge-accent-gold w-[180px]'
                      />)
                      : (<Button size='sm' className='button-secondary w-[180px]' onClick={() => changeMainWallet(item)}>
                        Make primary
                      </Button>)
                    )
                  }
                </div>
                <div>
                  <IconButton className="w-8 h-8" onClick={() => handleConfirmDelete(item.wallet)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                  </IconButton>
                </div>
              </div>

            </div>

          </Card>))}

        <ConfirmOrCancelDialog
          title={'Delete Wallet'}
          content={`Do you wish to delete the Wallet ${maskWallet(walletToDelete)}?`}
          isOpen={openConfirmDeleteDialog}
          onCancelClickEvent={handleCloseDialog}
          onConfirmClickEvent={deleteWalletHandler} />

      </div >
    </section >
  )
}

export default SetupAccount
