import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Navbar, 
  Typography, 
  IconButton,
  Button,
} from '@material-tailwind/react'
import ProfileMenu from '../Main/ProfileMenu'
import logo from '../../img/tektools-logotype.svg'
import iconDiscord from '../../img/icon-discord.svg'
import {
  authenticateUser,
  checkTokenValidity,
  setupAutoLogout,
} from '../../utils/authUtils'

//Logged user profile menu (navbar top right)
export default function Header({ onDrawerClick }) {
  const navigate = useNavigate()
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData'))
  )
  const [isActive, setIsActive] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)

  //Scroll effect
  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  //Link Discord Auth
  const handleClick = () => {
    const authUrl = process.env.REACT_APP_AUTH_DISCORD
    window.location.replace(authUrl)
  }

  //API
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    if (code && !userData) {
      authenticateUser(code)
        .then((data) => {
          setUserData(data)
        })
        .catch((error) => {
          //TODO: We need to show this error to the user.
          console.log('Failed to log using Discord code')
        })
    }
  }, [userData, navigate])

  // Verificação de validade do token
  useEffect(() => {
    checkTokenValidity()
  }, [])

  // Logout automático após 2 dias
  useEffect(() => {
    const cleanup = setupAutoLogout()
    return cleanup
  }, [])

  const handleAccount = () => {
    window.location.replace('/account')
  }

  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);


  return (
    <header className="sticky top-0 z-10">

      <Navbar className="border-b border-primary bg-background">

        <div className="container flex flex-row items-center px-0">

          {/* Top navbar hamburger menu icon */}
          <IconButton
            className="h-8 w-8 bg-transparent hover:bg-transparent text-grayscale-bright hover:text-white lg:hidden"
            onClick={onDrawerClick}
          >
            {openNav ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10">
                <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10">
                <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
              </svg>
            )}
          </IconButton>
          
          {/* Top navbar logo */}
          <div className="mr-3 ml-2 grow lg:grow-0">
            <a href="/">
              <span className="sr-only">TekTools</span>
              <img id="tektools-logo" src={logo} alt="TekTools" />
            </a>
          </div>

          {/* Top navbar text menu */}
          <div className="grow hidden lg:block">
            <ul className=" ml-3 mb-0 mt-0 flex flex-row justify-start text-grayscale-bright items-center uppercase">
              <Typography as="li" className="flex items-center pl-4 pr-4 border-r border-primary active:text-white hover:text-white">
                <a href="/#features">
                  Features
                </a>
              </Typography>

              <Typography as="li" className="flex items-center pl-4 pr-4 border-r border-primary active:text-white hover:text-white">
                <a href="/#pricing">
                  Pricing
                </a>
              </Typography>

              <Typography as="li" className="flex items-center pl-4 pr-4 active:text-white hover:text-white">
                <a href="/#faq">
                  FAQ
                </a>
              </Typography>
            </ul>
          </div>

          {/* Top navbar login button */}
          {
            userData
              ? (<ProfileMenu userData={userData} />)
              : (
                <Button onClick={handleClick} size="sm" className="button-primary w-auto my-[2px]">
                  <img src={iconDiscord} />
                  Login
                </Button>
              )
          }
        </div>
      </Navbar>
    </header>
  )
}
