import React from 'react'
import Faq from '../Faq/Faq'
import Feature from '../Feature/Feature'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Hero from '../Hero/Hero'
import Prices from '../prices/Prices'
import { DrawerMenu } from './DrawerMenu'

const Main = () => {
  const [open, setOpen] = React.useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  return (
    <>
      <DrawerMenu isOpen={open} onCloseClick={closeDrawer} />
      <Header onDrawerClick={openDrawer} />
      <Hero />
      <Feature />
      <Prices />
      <Faq />
      <Footer />
    </>
  )
}

export default Main
