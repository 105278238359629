import {
  Button,
  IconButton,
  Card,
  Chip,
  Spinner,
} from '@material-tailwind/react'

import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchCollections } from '../../utils/collectionUtils'
import { ConfirmOrCancelDialog } from '../../components/Dialogs/ConfirmOrCancelDialog'

const LoadHolderVerification = ({ search }) => {
  const guildId = localStorage.getItem('guildId')
  const [listCollections, setListCollections] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)

  const [openDialog, setOpenDialog] = useState(false)
  const handleOpenDialog = (collection) => {
    setSelectedCollection(collection)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedCollection(null)
  }

  const updateLoadCollection = () => {
    fetchCollections(guildId).then((data) => {
      setListCollections(data)
    })
  }

  useEffect(() => {
    if (!listCollections || listCollections.length === 0) {
      updateLoadCollection()
    }
  }, [])

  const disableCollectionById = async () => {
    if (!selectedCollection) return
    const infoDisable = {
      guildId: localStorage.getItem('guildId'),
      collectionId: selectedCollection._id,
      disable: true,
    }
    HttpService.disableCollectionById(infoDisable)
      .then(() => {
        toast.success('Collection disabled successfully!')
        updateLoadCollection()
        handleCloseDialog()
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
      })
  }

  // Short Address
  const shortAddress = (address) => {
    if (!address) return ''
    return `${address.substring(0, 3)}...${address.substring(address.length - 3)}`
  }

  return (
    <>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 pb-4">

        {!listCollections ? (

          //Card skeleton
          <Card className="unclickable-card flex">
            <div className="w-full h-full flex items-center justify-center">
              <Spinner className="w-12 h-12 text-secondary-bright" />
            </div>
          </Card>

        ) : (
          <>
            {Array.isArray(listCollections) &&
              listCollections.length > 0 &&
              listCollections
                ?.flatMap((holder) =>
                  holder?.CollectionNotifications.filter(
                    (collection) => collection.IsEnabled &&
                      (collection?.DisplayName?.toLowerCase().includes(search?.toLowerCase()) ||
                        collection?.VerifiedAddress?.toLowerCase().includes(search?.toLowerCase()) ||
                        collection?.TokenAddress?.toLowerCase().includes(search?.toLowerCase()) ||
                        collection?.CollectionMint?.toLowerCase().includes(search?.toLowerCase())))
                    .slice()
                    .reverse()
                    .map((collection, collectionIndex) => ({
                      holder,
                      collection,
                      collectionIndex,
                    }))
                )
                .map(
                  ({ holder, collection, collectionIndex }, holderIndex) => (

                    // Card loaded with staking list 
                    <Card className="unclickable-card flex flex-col justify-between" key={`${holderIndex}-${collectionIndex}`}>


                      {/*Card header*/}
                      <div className={`${collection.IsToken ? 'flex flex-row border-b border-accent-green pb-2 items-center' : 'flex flex-row border-b border-accent-blue pb-2 items-center'}`}>

                        {/* Collection name */}
                        <h4 className={`${collection.IsToken ? 'grow truncate text-accent-green' : 'grow truncate text-accent-blue'}`}>
                          {collection.DisplayName ||
                            collection.CollectionDisplayName ||
                            collection.CollectionName}
                        </h4>

                        {/* Collection type */}
                        <Chip
                          value={collection.IsToken ? 'Token' : 'NFT'}
                          className={`${collection.IsToken ? 'badge-accent-green' : 'badge-accent-blue'}`}
                        />

                      </div>

                      {/*Card body*/}

                      <div className='flex flex-row justify-start gap-4 pt-4 text-left h-[84px]'>

                        <div className='grow'>
                          {collection.IsToken === true ? (
                            <small>
                              Token Address:{' '}
                            </small>
                          ) : (
                            <small>
                              {collection.CollectionMint
                                ? 'Collection Mint'
                                : 'Creator Address'}
                            </small>
                          )}

                          {collection.IsToken === false ? (
                            <p className="truncate emphasis text-2xl">
                              {collection.CollectionMint
                                ? shortAddress(
                                  collection.CollectionMint
                                )
                                : shortAddress(
                                  collection.VerifiedAddress
                                )}
                            </p>
                          ) : (
                            <p className="truncate emphasis text-2xl">
                              {shortAddress(
                                collection.TokenAddress ||
                                collection.VerifiedAddress
                              )}
                            </p>
                          )}

                        </div>

                        <div>

                          {collection.IsToken === false && (
                            <>

                              <div className='py-1'>
                                <Chip value={`Listing ${(collection.Sales?.IsEnabled === true ? 'enabled' : 'disabled')}`} color={((collection.Listing?.IsEnabled || false) ? 'green' : 'amber')} />
                              </div>

                              <div className='py-1'>
                                {collection.Sales?.IsEnabled === true}
                                <Chip value={`Sales ${(collection.Sales?.IsEnabled === true ? 'enabled' : 'disabled')}`} color={(collection.Sales?.IsEnabled === true ? 'green' : 'amber')} />
                              </div>
                            </>
                          )}

                        </div>

                      </div>

                      {/*Card footer*/}
                      <div>

                        <hr />

                        <div className='flex flex-row gap-4'>

                          <div className='grow'>
                            <Button onClick={() => window.location.href = `/dashboard/holderverification/${collection._id}`} size='sm' className='button-secondary w-[180px]'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                              </svg>
                              Edit
                            </Button>
                          </div>

                          <div>
                            <IconButton onClick={() => handleOpenDialog(collection)} className="w-8 h-8">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                              </svg>
                            </IconButton>
                          </div>

                        </div>

                      </div>

                    </Card>
                  )
                )}
          </>
        )}

        {/* Delete warning dialog */}
        <ConfirmOrCancelDialog
          title={'Delete collection'}
          content={`Do you really want to delete the collection "${selectedCollection?.DisplayName}"? This action CANNOT be undone.`}
          isOpen={openDialog}
          onCancelClickEvent={handleCloseDialog}
          onConfirmClickEvent={disableCollectionById} />

      </div>
    </>
  )
}

export default LoadHolderVerification
