import { Button, Radio, Input } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { SeparatorDefault } from '../components/ui/separator'
import { SelectDefault } from '../../admin/components/ui/select'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'

const AntiBotMembers = () => {
  const guildId = localStorage.getItem('guildId')
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  const handleChannelChange = (selectedOption) => {
    setSelectedChannel(selectedOption)
  }

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption)
  }

  //button
  const saveClick = () => {
    if (!selectedChannel) {
      toast.error('Please select a channel!')
      return
    }
    if (!selectedRole) {
      toast.error('Please select a role!')
      return
    }
    const infoAntiBotMembers = {
      serverId: localStorage.getItem('guildId'),
      channelId: selectedChannel.value,
      roleId: selectedRole.value,
      captcha: isChecked,
    }
    HttpService.postUserVerification(infoAntiBotMembers)
      .then((response) => {
        setSelectedRole(null)
        setSelectedChannel(null)
        setIsChecked(false)
        toast.success('Message sent successfully!')
      })
      .catch((error) => {
        toast.error('Opss, something gone wrong.')
      })
  }
  return (

    <section className="container">

      <h1>Anti bot members</h1>

      <h3>User Verification</h3>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">

        <div>

          <SelectDefault
            label="Channel"
            placeholder="Select channel"
            isLoading={isLoading}
            options={dropdownChannels}
            value={selectedChannel}
            onChange={handleChannelChange}
          />

        </div>

        <div>

          <SelectDefault
            label="Role"
            placeholder="Select role"
            isLoading={isLoading}
            options={dropdownRoles}
            value={selectedRole}
            onChange={handleRoleChange}
            disabled={true}
          />

        </div>

      </div>

      <SeparatorDefault />

      <h3>Verification type</h3>

      <div className="flex flex-col">
        <Radio name="verificationType" label="Standard" checked={!isChecked} onChange={() => setIsChecked(!isChecked)} />
        <Radio name="verificationType" label="Captcha" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
      </div>

      {/* Send Button */}
      <div className='grid grid cols-1 lg:grid-cols-12 mt-10 pb-10'>
        <Button onClick={saveClick} className="button-primary lg:col-span-4" >
          Save
        </Button>
      </div>

    </section>

  )
}
export default AntiBotMembers
