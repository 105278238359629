import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar/Sidebar'
import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSideBar'
import { useMediaQuery } from 'react-responsive'

const AdminMain = () => {
  //This needs to be set according to the screen size. Is there a way to do this via CSS?
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <>
      <div className="flex h-screen">
        <div className="flex flex-row overflow-hidden w-full h-full">

          {
            (isMobile
              ? (
                <AdminSidebar isMobile={isMobile} isDrawerOpen={isDrawerOpen} onCloseDrawerClick={closeDrawer} />
              )
              : (
                <div className="sidebar-manager w-[298px] overflow-y-auto scrollbar-thin scrollbar-track-primary-deep scrollbar-thumb-grayscale-lucky bg-gradient-to-r from-[#1F1C1C] to-[#413A39] border-r border-primary">
                  <AdminSidebar isMobile={isMobile} isDrawerOpen={isDrawerOpen} onCloseDrawerClick={closeDrawer} />
                </div>
              )
            )
          }

          <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-track-primary-deep scrollbar-thumb-grayscale-lucky bg-background-manager bg-center bg-no-repeat bg-cover">
            <AdminHeader isMobile={isMobile} onOpenDrawerClick={openDrawer} />
            <Outlet />
          </div>

        </div>
      </div>
    </>
  )
}

export default AdminMain
