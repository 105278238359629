import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { fetchCollections } from '../../../utils/collectionUtils'
import { SelectDefault } from '../../components/ui/select'
import TraitRewards from './TraitRewards'

export const SpecificRewards = ({
  collectionsRewards,
  onSpecificRewardsChange,
  onSpecificRewardsChange: onCollectionsChange,
}) => {
  const guildId = localStorage.getItem('guildId')
  const [selectedCollection, setSelectedCollection] = useState(collectionsRewards || [])
  const [selectedCollectionIndex, setSelectedCollectionIndex] = useState(
    collectionsRewards && collectionsRewards.length > 0 ? 0 : null
  )

  useEffect(() => {
    setSelectedCollection(collectionsRewards || [])
    if ((collectionsRewards || []).length > 0 && selectedCollectionIndex === null) {
      setSelectedCollectionIndex(0)
    }
  }, [collectionsRewards])

  const handleSelectCollection = (index) => {
    setSelectedCollectionIndex(index)
  }

  const handleUpdateTraits = (collectionIndex, updatedTraits) => {
    const newCollections = [...selectedCollection]
    newCollections[collectionIndex] = {
      ...newCollections[collectionIndex],
      reward: updatedTraits,
    }
    setSelectedCollection(newCollections)
    if (onSpecificRewardsChange) {
      onSpecificRewardsChange(newCollections)
    }
  }

  //load collections
  const [collections, setCollections] = useState([])

  useEffect(() => {
    if (!collections || collections.length === 0) {
      fetchCollections(guildId).then((data) => {
        setCollections(data)
      })
    }
  }, [])

  const collectionOptions = collections?.flatMap(
    (collection) =>
      collection.CollectionNotifications?.filter(
        (notification) => notification.IsEnabled && !notification.IsToken
      ).map((notification) => ({
        value: [
          {
            CollectionAddress: notification.CollectionMint,
            VerifiedAddress: notification.VerifiedAddress,
          },
        ],
        label: notification.DisplayName,
      })) || []
  )

  const handleCollectionChange = (value) => {
    setSelectedCollection(value)
    if (onSpecificRewardsChange) onSpecificRewardsChange(value)
    if (value && value.length > 0 && selectedCollectionIndex === null) {
      setSelectedCollectionIndex(0)
    }
  }

  return (
    <div className="flex flex-col w-full gap-5 z-20">
      {/* Select Collection */}
      <div className='flex z-30'>
        <SelectDefault
          label="Select the collection(s) to stake."
          placeholder="Select Collection"
          isMulti={true}
          options={collectionOptions}
          onChange={handleCollectionChange}
          value={selectedCollection}
        />
      </div>

      {selectedCollection?.length > 0 && (
        <div
          className={`flex flex-row w-full border-b-2 gap-1 overflow-x-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic mb-5 ${selectedCollectionIndex !== null ? 'border-main' : ''}`}
        >
          {selectedCollection.map((collection, index) => (
            <Button
              key={index}
              className={`cursor-pointer p-3 rounded-none rounded-t border-none mt-5 lg:mt-0  ${selectedCollectionIndex === index ? 'bg-main' : 'shadow-none'}`}
              onClick={() => handleSelectCollection(index)}
            >
              {collection.label}
            </Button>
          ))}
        </div>
      )}
      {selectedCollectionIndex !== null ? (
        <div className="flex flex-col w-full z-20">
          {collectionsRewards.map((collection, index) => (
            <div
              key={index}
              className={selectedCollectionIndex === index ? 'block' : 'hidden'}
            >
              <TraitRewards
                onCollections={{
                  CollectionAddress: collection.value[0].CollectionAddress,
                  VerifiedAddress: collection.value[0].VerifiedAddress,
                }}
                rewards={collection.reward || []}
                onUpdateTraits={(updatedTraits) =>
                  handleUpdateTraits(index, updatedTraits)
                }
              />
            </div>
          ))}
        </div>
      ) : (
        <span>
          You can create custom trait specific reward based on your NFT metadata
          to give its holder extra rewards, example: NFTs with trait Head, trait
          value Golden Necklace, get 10 rewards per day.
        </span>
      )}
    </div>
  )
}

export default SpecificRewards
